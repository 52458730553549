/** Packages **/
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { CalendarOutlined } from "@ant-design/icons";
import {
  DateFormatPattern,
  NxpFormItem,
  NxpModal,
  NxpTable,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";

/** Services-Utils **/
import { Distribution } from "../../../services/distributions";

interface DistributeAlertModalProps {
  distributions: Distribution[];
  show: boolean;
  onClose: () => void;
}

const useStyles = createUseStyles(() => ({
  date: {
    alignItems: "center",
    "& .anticon-calendar": {
      padding: "0px  5px 0px 0px",
    },
    display: "flex",
  },
}));

const AddRoleModal: React.FC<DistributeAlertModalProps> = ({
  distributions,
  show,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns: ColumnProps<Distribution>[] = [
    {
      title: t("app.common.User"),
      dataIndex: ["recipient", "name"],
      width: 150,
      sorter: (a: Distribution, b: Distribution) =>
        sorterForString(a.recipient?.name, b.recipient?.name),
    },
    {
      title: t("app.common.ReadOn"),
      dataIndex: "readTime",
      width: 100,
      render: (_: any, distribution: Distribution) => {
        return distribution.readTime !== null ? (
          <div className={classes.date}>
            <CalendarOutlined />
            <NxpFormItem
              editing={false}
              controlType="input"
              controlProps={{
                value: moment(Number(distribution.readTime!)).format(
                  DateFormatPattern.date
                ),
              }}
            />
          </div>
        ) : (
          <NxpFormItem
            editing={false}
            controlType="input"
            controlProps={{
              value: "N/A",
            }}
          />
        );
      },
      sorter: (a: Distribution, b: Distribution) =>
        sorterForString(a.readTime, b.readTime),
    },
    {
      title: t("app.common.AcknowledgedOn"),
      dataIndex: "acknowledgeTime",
      width: 100,
      render: (_: any, distribution: Distribution) => {
        return distribution.acknowledgeTime !== null ? (
          <div className={classes.date}>
            <CalendarOutlined />
            <NxpFormItem
              editing={false}
              controlType="input"
              controlProps={{
                value: moment(Number(distribution.acknowledgeTime!)).format(
                  DateFormatPattern.date
                ),
              }}
            />
          </div>
        ) : (
          <NxpFormItem
            editing={false}
            controlType="input"
            controlProps={{
              value: "N/A",
            }}
          />
        );
      },
      sorter: (a: Distribution, b: Distribution) =>
        sorterForString(a.acknowledgeTime, b.acknowledgeTime),
    },
  ];

  const handleCloseButton = () => {
    onClose();
  };

  return (
    <NxpModal
      title={t("app.common.DistributeAlert")}
      visible={show}
      width="small"
      onCancel={handleCloseButton}
    >
      <NxpTable
        columns={columns as any}
        dataSource={distributions as any}
      ></NxpTable>
    </NxpModal>
  );
};

export default AddRoleModal;
