/** Packages **/
import React from "react";
import { createUseStyles } from "react-jss";

import { NxpPanel } from "@nexploretechnology/nxp-ui";
import { Col, List, Row } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";

/** Services-Utils **/
import useAppContext from "../../../hooks/useAppContext";
import { UserRightList } from "../../../services/app";

interface RightGroupPanelProps {
  checklist: Record<keyof UserRightList, boolean>;
  items: { title: string; value: keyof UserRightList }[];
  title: string;
  onItemCheck: (values: string, checked: boolean) => void;
}

const useStyle = createUseStyles(() => ({
  panel: {
    "& .ant-collapse-content-box": {
      padding: "0px 16px 0px 40px",
    },
    marginBottom: 10,
  },
  listItemRow: {
    width: "100%",
  },
}));

const RightGroupPanel: React.FC<RightGroupPanelProps> = ({
  checklist,
  items,
  title,
  onItemCheck,
}: RightGroupPanelProps) => {
  const classes = useStyle();
  const { hasRight } = useAppContext();

  const masterChecked = items.every(
    (item: { title: string; value: keyof UserRightList }) =>
      checklist[item.value]
  );

  const indeterminateChecked = items.some(
    (item: { title: string; value: keyof UserRightList }) =>
      checklist[item.value]
  );

  return (
    <NxpPanel
      className={classes.panel}
      titleContent={title}
      defaultCollapsed={true}
      extra={
        <Checkbox
          disabled={!hasRight("right-update")}
          checked={masterChecked}
          indeterminate={indeterminateChecked && !masterChecked}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            for (const item of items) {
              onItemCheck(item.value, e.target.checked ? true : !masterChecked);
            }
            return true;
          }}
        />
      }
    >
      <List
        dataSource={items}
        renderItem={(item) => (
          <List.Item>
            <Row className={classes.listItemRow}>
              <Col flex="auto">{item.title}</Col>
              <Col>
                <Checkbox
                  disabled={!hasRight("right-update")}
                  checked={checklist[item.value]}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    onItemCheck(item.value, e.target.checked);
                    return true;
                  }}
                />
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </NxpPanel>
  );
};

export default RightGroupPanel;
