/** Packages **/
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

/** Services-Utils **/
import { Role } from "../../../services/roles";

interface EditRoleModalProps {
  role: Role;
  show: boolean;
  onClose: () => void;
  onDefectCreated?: () => void;
  onEditRole: (form: EditRoleForm) => Promise<boolean>;
}

export interface EditRoleForm {
  name: string;
  code?: string;
  description: string;
}

const emptyForm = {
  name: "",
  code: "",
  description: "",
};

const EditRoleModal: React.FC<EditRoleModalProps> = ({
  show,
  role,
  onEditRole,
  onClose,
}) => {
  const [form, setForm] = useState<EditRoleForm>(emptyForm);
  const [saving, setSaving] = useState<boolean>(false);

  const { t } = useTranslation();

  const formSchema = yup.object().shape({
    name: yup.string().nullable().required(t("roleSetting.RoleNameIsRequired")),
    code: yup.string().nullable().required(t("roleSetting.RoleCodeIsRequired")),
    description: yup.string().nullable(),
  });

  const formItems: NxpFormGridItemProps<EditRoleForm>[] = [
    {
      controlType: "input",
      label: t("roleSetting.RoleName"),
      itemFieldName: "name",
      required: true,
      span: 12,
    },
    {
      controlType: "input",
      label: t("roleSetting.RoleCode"),
      itemFieldName: "code",
      required: true,
      span: 12,
      controlProps: {
        disabled: true,
      },
    },
    {
      controlType: "input",
      label: t("app.common.Description"),
      itemFieldName: "description",
      required: false,
      span: 24,
    },
  ];

  const handleSaveValidated = async () => {
    setSaving(true);
    onEditRole(form).then((success) => {
      if (success) {
        onClose();
      } else {
        setSaving(false);
      }
    });
  };

  const [validationError, , , saveWithValidate] = useYupValidate<EditRoleForm>(
    form,
    formSchema,
    handleSaveValidated
  );

  const handleCloseButton = () => {
    if (saving) return;
    onClose();
  };

  const handleFormStateChange = (
    fieldName: keyof typeof form,
    value: unknown
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSaveButton = () => {
    saveWithValidate(undefined);
  };

  useEffect(() => {
    return () => {
      if (role) {
        setForm({
          name: role.name,
          code: role.code,
          description: role.description,
        });
      }
      setSaving(false);
    };
  }, [show, role]);

  return (
    <NxpModal
      width="medium"
      title={t("app.common.EditRole")}
      visible={show}
      showMandatoryLabel
      onCancel={handleCloseButton}
      footer={
        <>
          <NxpButton onClick={handleCloseButton}>
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton onClick={handleSaveButton} loading={saving}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={form}
        onFormStateChange={handleFormStateChange}
      />
    </NxpModal>
  );
};

export default EditRoleModal;
