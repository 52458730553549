/** Packages **/
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";

import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import {
  DateFormatPattern,
  NxpFormItem,
  NxpHeader,
  NxpTableLazy,
  NxpTabs,
  TabPane,
} from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";

/** Services-Utils **/
import useAppContext from "../../hooks/useAppContext";
import {
  Distribution,
  updateReadDistribution,
} from "../../services/distributions";
/** Components **/
import { AlertsReceivedContext } from "./AlertsReceivedContainer";

interface AlertsReceivedLayoutProps {}

const useStyles = createUseStyles(() => ({
  date: {
    alignItems: "center",
    "& .anticon-calendar": {
      padding: "0px  5px 0px 0px",
    },
    display: "flex",
  },
}));

const AlertsReceivedLayout: React.FC<AlertsReceivedLayoutProps> = () => {
  const {
    alertsReceived,
    categoryByCount,
    lazyLoadStatus,
    onLazyLoad,
    onRowClick,
    onTabChange,
    onInputChange,
    onSortingChange,
  } = useContext(AlertsReceivedContext);

  const appContext = useAppContext();
  const { hasRight, serviceConfig } = appContext;
  const routeParam = useParams<{
    entityId: string;
    alertId: string;
  }>();
  const classes = useStyles();
  const { t } = useTranslation();

  const columns: ColumnProps<Distribution>[] = [
    {
      title: t("app.common.Subject"),
      dataIndex: ["alert", "subject"],
      width: 100,
      sorter: true,
    },
    {
      title: t("app.common.AlertNumber"),
      dataIndex: ["alert", "code"],
      width: 100,
      sorter: true,
    },
    {
      title: t("app.common.CreatedBy"),
      dataIndex: ["creator", "name"],
      width: 100,
      sorter: true,
    },
    {
      title: t("app.common.LastDistribution"),
      dataIndex: ["alert", "distributionTime"],
      width: 100,
      render: (_: any, distribution: Distribution) => {
        return (
          <div className={classes.date}>
            <CalendarOutlined />
            <NxpFormItem
              editing={false}
              controlType="input"
              controlProps={{
                value: moment(
                  Number(distribution?.alert?.distributionTime)
                ).format(DateFormatPattern.date),
              }}
            />
          </div>
        );
      },
      sorter: true,
    },
    {
      title: t("app.common.AcknowledgedOn"),
      dataIndex: ["alert", "acknowledgeTime"],
      width: 100,
      render: (_: any, distribution: Distribution) => {
        return distribution.acknowledgeTime !== null ? (
          <div className={classes.date}>
            <CalendarOutlined />
            <NxpFormItem
              editing={false}
              controlType="input"
              controlProps={{
                value: moment(Number(distribution.acknowledgeTime!)).format(
                  DateFormatPattern.date
                ),
              }}
            />
          </div>
        ) : (
          <NxpFormItem
            editing={false}
            controlType="input"
            controlProps={{
              value: "N/A",
            }}
          />
        );
      },
      sorter: true,
    },
  ];

  return (
    <>
      <NxpHeader
        titleContent={t("app.common.AlertsReceived")}
        actionContent={
          <Row gutter={10}>
            <Col>
              <NxpFormItem
                controlType="input"
                controlProps={{
                  onChange: (e) => onInputChange(e.target.value),
                  prefix: <SearchOutlined />,
                  placeholder: t("app.common.Search"),
                }}
              />
            </Col>
          </Row>
        }
      />
      <NxpTabs defaultActiveKey="all" onChange={onTabChange}>
        <TabPane
          tab={`${t("app.common.All")} (${categoryByCount?.all})`}
          key="all"
        ></TabPane>
        <TabPane
          tab={`${t("app.common.Read")} (${categoryByCount?.read})`}
          key="read"
        ></TabPane>
        <TabPane
          tab={`${t("app.common.Unread")} (${categoryByCount?.unread})`}
          key="unread"
        ></TabPane>
        {hasRight("alert-acknowledge") && (
          <TabPane
            tab={`${t("app.common.Acknowledged")} (${
              categoryByCount?.acknowledged
            })`}
            key="acknowledged"
          ></TabPane>
        )}
        {hasRight("alert-acknowledge") && (
          <TabPane
            tab={`${t("app.common.PendingAcknowledgement")} (${
              categoryByCount?.pendingAcknowledgement
            })`}
            key="pending-acknowledgement"
          ></TabPane>
        )}
      </NxpTabs>
      <NxpTableLazy
        columns={columns as any}
        useDefaultHeight={-129}
        pagination={false}
        dataSource={alertsReceived as any}
        lazyLoadStatus={lazyLoadStatus}
        onLazyLoad={onLazyLoad}
        onChange={(pagination, filters, sorter: any) => {
          onSortingChange!(sorter.field, sorter.order);
        }}
        onRow={(alert) => ({
          onClick: () => {
            if (onRowClick) {
              onRowClick(alert.id);
              updateReadDistribution(
                routeParam?.entityId,
                alert.id,
                serviceConfig
              );
            }
          },
        })}
      ></NxpTableLazy>
    </>
  );
};

export default AlertsReceivedLayout;
