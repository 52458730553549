/** Packages **/
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";

import { notify, NxpButton, NxpModal } from "@nexploretechnology/nxp-ui";

/** Services-Utils **/
import useAppContext from "../../../hooks/useAppContext";
import { updateReadAcknowledge } from "../../../services/distributions";
import { sendFile } from "../../../services/file";

interface SignatureModalProps {
  show: boolean;
  onClose: () => void;
  onRefresh: () => void;
}

const useStyles = createUseStyles(() => ({
  canvas: {
    border: "1px solid #8A9999",
  },
  signText: {
    color: "#8A9999",
    fontSize: "14px",
    margin: "0px",
    padding: "5px 0px 0px 0px",
    userSelect: "none",
  },
}));

const SignatureModal: React.FC<SignatureModalProps> = ({
  show,
  onClose,
  onRefresh,
}) => {
  const [saving] = useState<boolean>();

  const appContext = useAppContext();
  const { serviceConfig } = appContext;
  const routeParam = useParams<{
    entityId: string;
    distributionId: string;
  }>();
  const classes = useStyles();
  const { t } = useTranslation();

  let sigCanvas: SignatureCanvas;

  const handleSubmitButton = async () => {
    /** User cannot submit if signature canvas hasn't been modified. **/
    if (checkEmpty())
      notify.error(t("signatureModal.notifyError.EmptySignature"));
    else {
      try {
        const blob = await (await fetch(getSignature())).blob();
        const data = new FormData();
        data.append("file", blob);

        await sendFile(routeParam?.entityId, serviceConfig, data).then((file) =>
          updateReadAcknowledge(
            routeParam?.entityId,
            Number(routeParam?.distributionId),
            file.id!,
            serviceConfig
          )
        );
      } catch (error: any) {
        notify.error(
          `${t("signatureModal.notifyError.SubmitSignature")}: ${error}`
        );
      }

      notify.success(t("signatureModal.notifySuccess.SubmitSignature"));
      onRefresh();
      onClose();
    }
  };

  const handleCancelButton = () => {
    clearCanvas();
    onClose();
  };

  const checkEmpty = () => {
    if (!sigCanvas) return true;
    return sigCanvas.isEmpty();
  };

  const clearCanvas = () => {
    sigCanvas.clear();
  };

  const getSignature = () => {
    return sigCanvas.toDataURL();
  };

  return (
    <NxpModal
      title={t("app.common.Signature")}
      width="small"
      visible={show}
      onCancel={handleCancelButton}
      footer={
        <>
          <NxpButton type="default" onClick={handleCancelButton}>
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton onClick={handleSubmitButton} loading={saving}>
            {t("app.common.Submit")}
          </NxpButton>
        </>
      }
    >
      <div className={classes.canvas}>
        <SignatureCanvas
          canvasProps={{
            width: 500,
            height: 100,
          }}
          throttle={0}
          dotSize={1}
          ref={(ref) => {
            sigCanvas = ref!;
          }}
        ></SignatureCanvas>
      </div>
      <p className={classes.signText}>{t("signatureModal.SignText")}</p>
    </NxpModal>
  );
};

export default SignatureModal;
