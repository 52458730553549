/** Packages **/
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import {
  LazyLoadStatus,
  notify,
  useDebouncedValue,
} from "@nexploretechnology/nxp-ui";
import { isEmpty } from "lodash";

/** Services-Utils **/
import useAppContext from "../../hooks/useAppContext";
import { Alert } from "../../services/alerts";
import {
  Distribution,
  getDistributions,
  getDistributionsCount,
} from "../../services/distributions";
/** Components **/
import AlertsReceivedLayout from "./AlertsReceivedLayout";

interface AlertsReceivedContextType {
  alertsReceived: Alert[] | undefined;
  categoryByCount: CategoryLengths | undefined;
  filter: string;
  lazyLoadStatus: LazyLoadStatus;
  showAddRoleModal: boolean;
  onInputChange: (value: string) => void;
  onLazyLoad: () => void;
  onRowClick: (alertId: string) => void;
  onSortingChange?: (sortBy: string, order: "ASC" | "DESC") => void;
  onTabChange: (alertId: string) => void;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  setShowAddRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AlertsReceivedContext =
  React.createContext<AlertsReceivedContextType>(
    {} as AlertsReceivedContextType
  );

interface AlertsReceivedContainerProps {}

interface CategoryLengths {
  all: number;
  read: number;
  unread: number;
  acknowledged: number;
  pendingAcknowledgement: number;
}

let _CATEGORY_BY_COUNT: CategoryLengths = {
  all: 0,
  read: 0,
  unread: 0,
  acknowledged: 0,
  pendingAcknowledgement: 0,
};

const QUERY_SIZE: number = 50; /** Request max distribution row size. **/

const AlertsReceivedContainer: React.FC<AlertsReceivedContainerProps> = () => {
  const [activeTab, setActiveTab] = useState<string>();
  const [alertsReceived, setAlertsReceived] = useState<Distribution[]>([]);
  const [categoryByCount, setCategoryByCount] =
    useState<CategoryLengths>(_CATEGORY_BY_COUNT);
  const [filter, setFilter] = useState<string>("");
  const [lazyLoadStatus, setLazyLoadStatus] = useState(LazyLoadStatus.ready);
  const [order, setOrder] = useState<"ASC" | "DESC">("DESC");
  const [page, setPage] = useState<number>(1);
  const [showAddRoleModal, setShowAddRoleModal] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<undefined | string>("code");

  const appContext = useAppContext();
  const { activeUser, serviceConfig, hasRight } = appContext;
  const history = useHistory();
  const routeParam = useParams<{
    entityId: string;
  }>();
  const { t } = useTranslation();

  const searchValueDebounced = useDebouncedValue(filter, 1000, [filter]);

  const handleInputChange = (value: string) => {
    /** We want to reset the AlertsReceived array and page state whenever input changes. **/
    setAlertsReceived([]);
    setPage(1);
    setFilter(value);
    setLazyLoadStatus(LazyLoadStatus.loading);
  };

  const handleLazyLoad = () => {
    setPage(page + 1);
  };

  const handleRowClick = (distributionId: string) => {
    history.push(
      `/entities/${routeParam?.entityId}/alerts-received/${distributionId}`
    );
  };

  const handleSortingChange = (field: any, order: any) => {
    if (order) {
      /** Mapping of dataIndex of column attribute to backend query sortBy string. **/
      if (field.includes("subject")) field = "subject";
      if (field.includes("code")) field = "code";
      if (field.includes("name")) field = "creator";
      if (field.includes("distributionTime"))
        field = "alertLastDistributionTime";
      if (field.includes("acknowledgeTime")) field = "acknowledgeTime";

      /** We want to reset the AlertsReceived array and page state while setting the order and field. **/
      setAlertsReceived([]);
      setPage(1);
      setOrder(order === "descend" ? "DESC" : "ASC");
      setSortBy(field);
    }
  };

  const handleTabChange = (key: string) => {
    if (key !== activeTab) {
      setAlertsReceived([]);
      setPage(1);
      setActiveTab(key);
      setLazyLoadStatus(LazyLoadStatus.ready);
    }
  };

  const fetch = useCallback(async () => {
    /** We need activeUser in order to retrieve initial distribution count. **/
    if (!activeUser?.id) return;

    let query = `?recipientId=${activeUser?.id}&page=${page}&size=${QUERY_SIZE}&sortBy=${sortBy}&sortDirection=${order}`;

    if (!isEmpty(filter)) query += `&keyword=${filter}`;

    switch (activeTab) {
      case "read":
        query += `&status=read`;
        break;
      case "unread":
        query += `&status=unread`;
        break;
      case "acknowledged":
        query += `&status=acknowledged`;
        break;
      case "pending-acknowledgement":
        query += `&status=pending-acknowledgement`;
        break;
    }

    try {
      await getDistributions(routeParam?.entityId, serviceConfig, query).then(
        (distributions: Distribution[]) => {
          setAlertsReceived((prevState) => {
            setLazyLoadStatus(LazyLoadStatus.ready);

            /** Case that backend returns no more new items. **/
            if (distributions.length === 0) {
              setLazyLoadStatus(LazyLoadStatus.noMoreItem);
            }

            /** Case that backend cannot find any matching search results. **/
            if (distributions.length === 0 && page === 1 && !isEmpty(filter))
              return [];

            /** Case that there's existing data but page state incremented by 1, but we don't want to purge array. **/
            if (distributions.length === 0 && page !== 1 && !isEmpty(filter))
              return prevState;

            /** Optimization purposes by mapping only certain key values from the response array. **/
            return [
              ...prevState.concat(
                distributions.map(
                  ({ id, alert, creator, acknowledgeTime }) => ({
                    key: id,
                    id,
                    alert,
                    creator,
                    acknowledgeTime,
                  })
                )
              ),
            ];
          });
        }
      );
    } catch (error: any) {
      if (!hasRight("alert-receive")) return;
      notify.error(
        `${t("alertsRetrieved.notifyError.RetrieveDistributions")}: ${error}`
      );
    }

    query = `?recipientId=${activeUser?.id}`;
    try {
      await getDistributionsCount(
        routeParam?.entityId,
        serviceConfig,
        query
      ).then((distributionsCount) => {
        _CATEGORY_BY_COUNT.all = distributionsCount.all;
        _CATEGORY_BY_COUNT.read = distributionsCount.read;
        _CATEGORY_BY_COUNT.unread = distributionsCount.unread;
        _CATEGORY_BY_COUNT.acknowledged = distributionsCount.acknowledged;
        _CATEGORY_BY_COUNT.pendingAcknowledgement =
          distributionsCount[`pending-acknowledgement`];
        setCategoryByCount({ ..._CATEGORY_BY_COUNT });
      });
    } catch (error: any) {
      notify.error(
        `${t(
          "alertsRetrieved.notifyError.RetrieveDistributionCounts"
        )}: ${error}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    activeUser?.id,
    order,
    page,
    routeParam?.entityId,
    searchValueDebounced,
    serviceConfig,
    sortBy,
  ]);

  /** Initialization of component and update component with reference to CallBack. **/
  useEffect(() => {
    fetch();
  }, [fetch]);

  /** Update component with reference to searchValueDebounced CallBack. **/
  useEffect(() => {}, [searchValueDebounced]);

  return (
    <AlertsReceivedContext.Provider
      value={{
        alertsReceived,
        categoryByCount,
        filter,
        lazyLoadStatus,
        showAddRoleModal,
        onInputChange: handleInputChange,
        onLazyLoad: handleLazyLoad,
        onRowClick: handleRowClick,
        onSortingChange: handleSortingChange,
        onTabChange: handleTabChange,
        setFilter,
        setShowAddRoleModal,
      }}
    >
      {hasRight("alert-receive") && <AlertsReceivedLayout />}
    </AlertsReceivedContext.Provider>
  );
};

export default AlertsReceivedContainer;
