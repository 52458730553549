import { ServiceConfig } from "../utils/backend";

export interface Attachment {
  blob?: string;
  createdAt?: string;
  entityId?: string;
  id?: number;
  imageHeight?: number;
  imageWidth?: number;
  name?: string;
  originalBlob?: string;
  originalUrl?: string;
  size?: number;
  thumbnailBlob?: string;
  thumbnailUrl?: null;
  type?: string;
  url?: string;
}

export const sendFile = async (
  entityId: string,
  serviceConfig: ServiceConfig,
  data?: FormData
) => {
  const response = await fetch(
    `${serviceConfig.apiBaseUrl}/entities/${entityId}/files`,
    {
      headers: {
        Authorization: `Bearer ${serviceConfig.token}` || "",
        accept: "application/json",
      },
      method: "POST",
      body: data,
    }
  );
  const attachment = (await response.json()) as Attachment;
  return attachment;
};
