import { reviveJsonDateTime } from "../date/jsonDateTime";
import CustomApiError, {
  CustomApiErrorServerResponseData,
} from "./customApiError";

export default async function httpRequest<R = any>(
  url: string,
  options?: RequestInit,
  retryLimit = 0,
  lastRetryDelay = 2000
) {
  const requestSettings: RequestInit = {
    credentials: "same-origin",
    ...options,
    headers: {
      accept: "application/json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      ...options?.headers,
    },
  };

  return executeHttpRequest<R>(
    url,
    requestSettings,
    retryLimit,
    0,
    lastRetryDelay
  );
}

async function executeHttpRequest<R>(
  url: string,
  requestSettings: RequestInit,
  retryLimit: number,
  retryCount: number,
  lastRetryDelay: number,
  retryException?: Error
): Promise<R> {
  try {
    const response = await fetch(url, requestSettings);
    if (!response.ok) {
      // retry for token expiration
      // if (token expired && retryTrack < retry){
      // await refreshToken()
      // return executeRequestApi<T>(path, {...requestSettings, headers:...}, retryLimit, retryCount + 1, responseError)
      // }
      let errorJson: CustomApiErrorServerResponseData | undefined = undefined;
      try {
        errorJson = await response.json();
      } catch {
        // nothing to do
      }
      throw new CustomApiError(
        url,
        requestSettings,
        response.status,
        response.statusText,
        errorJson,
        undefined,
        retryCount,
        retryException,
        true // stop retry for defined api server error
      );
    }
    // success
    const respText = await response.text();
    if (respText.trim() === "") {
      return null as any;
    }
    const respJson = JSON.parse(respText, reviveJsonDateTime) as R;
    return respJson;
  } catch (err) {
    const customApiError =
      err instanceof CustomApiError
        ? err
        : new CustomApiError(
            url,
            requestSettings,
            undefined,
            undefined,
            undefined,
            err as Error,
            retryCount,
            retryException
          );

    // last retry, wait [lastRetryDelay] milliseconds
    if (retryCount === retryLimit - 1) {
      await new Promise((resolve) => setTimeout(resolve, lastRetryDelay));
    }

    // retry
    if (retryCount < retryLimit && !customApiError.stopRetry) {
      return executeHttpRequest<R>(
        url,
        requestSettings,
        retryLimit,
        retryCount + 1,
        lastRetryDelay,
        customApiError
      );
    }
    // error persist after all retries
    throw customApiError;
  }
}
