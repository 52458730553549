import { useRef } from "react";
import { useRouteMatch } from "react-router";
import { useKeycloak } from "@react-keycloak/web";

import useAppContext from "../../../hooks/useAppContext";
import {
  createMyUserPreference,
  getEntityInfo,
  getMe,
  getMyUserPreference,
  getUserRightList,
  getUsers,
} from "../../../services/app";
import { getRoles } from "../../../services/roles";

// for setting cache data in app context based on route param
const useRouteAppContextUpdate = () => {
  const { keycloak } = useKeycloak();

  const appContext = useAppContext();
  const loadingEntityIdRef = useRef("");
  const loadingLanguage = useRef(false);

  const entityId =
    useRouteMatch<{ entityId: string }>(`/entities/:entityId`)?.params.entityId;

  if (!appContext.serviceConfig.token) return;

  if (entityId && keycloak) {
    if (appContext.activeUser && !loadingLanguage.current) {
      loadingLanguage.current = true;
      getMyUserPreference(appContext.serviceConfig).then((userPreference) => {
        const languagePreference = userPreference.find(
          (u) => u.application === "entity" && u.parameter === "language"
        );
        if (languagePreference) {
          appContext.onAppContextCacheItemUpdate(
            "language",
            languagePreference.value
          );
        } else {
          createMyUserPreference(appContext.serviceConfig, {
            application: "entity",
            parameter: "language",
            value: "en",
          }).then((newUserPreference) => {
            appContext.onAppContextCacheItemUpdate(
              "language",
              newUserPreference.value
            );
          });
        }
      });
    }

    if (
      appContext.activeEntity?.id !== entityId &&
      loadingEntityIdRef.current !== entityId
    ) {
      loadingEntityIdRef.current = entityId;

      getEntityInfo(entityId, appContext.serviceConfig).then((entity) => {
        appContext.onAppContextCacheItemUpdate("activeEntity", entity);
      });

      getMe(entityId, appContext.serviceConfig).then((user) => {
        appContext.onAppContextCacheItemUpdate("activeUser", user);
      });

      getUserRightList(entityId, appContext.serviceConfig).then(
        (userRightList) => {
          appContext.onAppContextCacheItemUpdate(
            "userRightList",
            userRightList
          );
        }
      );

      getRoles(entityId, appContext.serviceConfig).then((roles) => {
        appContext.onAppContextCacheItemUpdate("roles", roles);
      });

      getUsers(entityId, appContext.serviceConfig).then((users) => {
        appContext.onAppContextCacheItemUpdate("users", users);
      });
    }
  }
};

export default useRouteAppContextUpdate;
