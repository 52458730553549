import { Alert } from "./alerts";
import { User } from "./app";
import { apiRequest, ServiceConfig } from "../utils/backend";
import { Attachment } from "./file";

export interface Distribution {
  acknowledgeTime?: string | null;
  alert?: Alert;
  createTime?: string;
  creator?: User;
  entityId?: string;
  id?: number;
  readTime?: string | null;
  recipient?: User;
  signature?: Attachment;
}

export const getDistributions = async (
  entityId: string,
  serviceConfig: ServiceConfig,
  query?: string
) => {
  return await apiRequest(
    `/entities/${entityId}/distributions` + query,
    serviceConfig
  );
};

export const createDistribution = async (
  entityId: string,
  alertId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/distributions`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        alert: alertId,
      }),
    }
  );
};

export const getDistributionsCount = async (
  entityId: string,
  serviceConfig: ServiceConfig,
  query?: string
) => {
  return await apiRequest(
    `/entities/${entityId}/distributions/count` + query,
    serviceConfig
  );
};

export const getDistribution = async (
  entityId: string,
  distributionId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/distributions/${distributionId}`,
    serviceConfig
  );
};

export const updateReadDistribution = async (
  entityId: string,
  distributionId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/distributions/${distributionId}/read`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
};

export const updateReadAcknowledge = async (
  entityId: string,
  distributionId: number,
  signature: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/distributions/${distributionId}/acknowledge`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        signature: signature,
      }),
    }
  );
};
