/** Packages **/
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";

import { CalendarOutlined } from "@ant-design/icons";
import {
  DateFormatPattern,
  notify,
  NxpFormItem,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";

/** Services-Utils **/
import useAppContext from "../../hooks/useAppContext";
import { Alert, getAlert } from "../../services/alerts";
import { getEntityInfo } from "../../services/app";
import { Distribution, getDistribution } from "../../services/distributions";
/** Components **/
import AlertPageLayout from "./AlertPageLayout";

export interface DistributionRow {
  key: string;
  entity: string;
  read: string;
  acknowledgedBy: string;
  distributedOn: string;
}

interface AlertPageContainerProps {}

const useStyles = createUseStyles(() => ({
  date: {
    alignItems: "center",
    "& .anticon-calendar": {
      padding: "0px  5px 0px 0px",
    },
    display: "flex",
  },
}));

const AlertPageContainer: React.FC<AlertPageContainerProps> = () => {
  const [alert, setAlert] = useState<Alert>();
  const [distribution, setDistribution] = useState<Distribution>();
  const [distributionRows, setDistributionRows] = useState<
    DistributionRow[] | undefined
  >(undefined);
  const [selectedDistributionRow, setSelectedDistributionRow] =
    useState<DistributionRow>();
  const [showDistributeAlertModal, setShowDistributeAlertModal] =
    useState<boolean>(false);
  const [showEditRoleModal, setShowEditRoleModal] = useState<boolean>(false);
  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);

  const appContext = useAppContext();
  const { activeEntity, serviceConfig, hasRight } = appContext;
  const routeParam = useParams<{
    entityId: string;
    alertId: string;
    distributionId: string;
  }>();
  const classes = useStyles();
  const { t } = useTranslation();

  const columns: ColumnProps<DistributionRow>[] = [
    {
      title: t("app.common.Entity"),
      dataIndex: "entity",
      width: 100,
      sorter: (a: DistributionRow, b: DistributionRow) =>
        sorterForString(a.entity, b.entity),
    },
    {
      title: t("app.common.Read"),
      dataIndex: "read",
      width: 100,
      sorter: (a: DistributionRow, b: DistributionRow) =>
        sorterForString(a.read, b.read),
    },
    {
      title: t("app.common.AcknowledgedBy"),
      dataIndex: "acknowledgedBy",
      width: 100,
      sorter: (a: DistributionRow, b: DistributionRow) =>
        sorterForString(a.acknowledgedBy, b.acknowledgedBy),
    },
    {
      title: t("app.common.DistributedOn"),
      dataIndex: "distributedOn",
      width: 100,
      render: (_: any, distributionRow: DistributionRow) => {
        return distributionRow.distributedOn !== "N/A" ? (
          <div className={classes.date}>
            <CalendarOutlined />
            <NxpFormItem
              editing={false}
              controlType="input"
              controlProps={{
                value: distributionRow.distributedOn,
              }}
            />
          </div>
        ) : (
          <NxpFormItem
            editing={false}
            controlType="input"
            controlProps={{
              value: "N/A",
            }}
          />
        );
      },
      sorter: (a: DistributionRow, b: DistributionRow) =>
        sorterForString(a.distributedOn, b.distributedOn),
    },
  ];

  const handleRefresh = () => {
    fetch();
  };

  const handleRowClick = (distributionRow: DistributionRow) => {
    setSelectedDistributionRow(distributionRow);
    setShowDistributeAlertModal(true);
  };

  const distributionCalculations = useCallback(() => {
    let INITIAL_DISTRIBUTION_ROWS: DistributionRow[] = [];

    let uniqueEntityIds = Array.from(
      new Set(
        alert?.distributions?.map((distribution) => distribution.entityId)
      )
    ) as string[];

    uniqueEntityIds.forEach(async (entityId, index) => {
      await getEntityInfo(entityId!, serviceConfig).then((entity) => {
        let acknowledgedBy: string[] = [];

        alert?.distributions
          ?.filter(
            (distribution) =>
              distribution.acknowledgeTime !== null &&
              distribution.entityId === uniqueEntityIds[index]
          )
          .forEach((item) => acknowledgedBy.push(item.recipient?.name!));

        INITIAL_DISTRIBUTION_ROWS.push({
          key: entity.id,
          entity: entity.name,
          read: `${alert?.distributions
            ?.filter(
              (distribution: Distribution) =>
                distribution.readTime !== null &&
                distribution.entityId === uniqueEntityIds[index]
            )
            .length!.toString()!}/${
            alert?.distributions?.filter(
              (distribution: Distribution) =>
                distribution.entityId === uniqueEntityIds[index]
            ).length
          }`,
          acknowledgedBy: acknowledgedBy.length
            ? acknowledgedBy.join(", ")
            : "N/A",
          distributedOn:
            alert?.distributionTime !== "0"
              ? moment(Number(alert?.distributionTime!)).format(
                  DateFormatPattern.date
                )
              : "N/A",
        });
        if (INITIAL_DISTRIBUTION_ROWS.length === uniqueEntityIds.length) {
          setDistributionRows([...INITIAL_DISTRIBUTION_ROWS]);
        }
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert?.distributions?.length]);

  const fetch = useCallback(() => {
    if (!activeEntity) return;
    /** If the item is an alert. **/
    if (routeParam.alertId) {
      if (!(hasRight("alert-create") || hasRight("alert-distribute"))) return;
      try {
        getAlert(
          routeParam?.entityId,
          Number(routeParam?.alertId),
          serviceConfig
        ).then((_alert) => setAlert(_alert));
      } catch (error: any) {
        notify.error(`${t("alertPage.notifyError.RetrieveAlert")}: ${error}`);
      }
    } /** If the item is a distribution. **/ else if (
      routeParam.distributionId
    ) {
      if (!hasRight("alert-receive")) return;
      try {
        getDistribution(
          routeParam?.entityId,
          Number(routeParam?.distributionId),
          serviceConfig
        ).then((_distribution) => setDistribution(_distribution));
      } catch (error: any) {
        notify.error(
          `${t("alertPage.notifyError.RetrieveDistribution")}: ${error}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeEntity,
    routeParam.alertId,
    routeParam.distributionId,
    routeParam?.entityId,
    serviceConfig,
  ]);

  /** Initialization of component and update component with reference to CallBack. **/
  useEffect(() => {
    fetch();
  }, [fetch]);

  /** Update component with reference to dependencies in distributionCalculations Callback. **/
  useEffect(() => {
    distributionCalculations();
  }, [distributionCalculations]);

  return hasRight("alert-receive") ||
    hasRight("alert-create") ||
    hasRight("alert-distribute") ? (
    <AlertPageLayout
      alert={alert!}
      columns={columns}
      distribution={distribution!}
      distributionRows={distributionRows}
      selectedDistributionRow={selectedDistributionRow!}
      showDistributeAlertModal={showDistributeAlertModal}
      showEditRoleModal={showEditRoleModal}
      showSignatureModal={showSignatureModal}
      onRefresh={handleRefresh}
      onRowClick={handleRowClick}
      setShowDistributeAlertModal={setShowDistributeAlertModal}
      setShowEditRoleModal={setShowEditRoleModal}
      setShowSignatureModal={setShowSignatureModal}
    />
  ) : (
    <></>
  );
};

export default AlertPageContainer;
