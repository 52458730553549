/** Packages **/
import React from "react";
import { useTranslation } from "react-i18next";

import { NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";

/** Components **/
import AppFakeTableHeader from "../../components/AppFakeTableHeader";
/** Services-Utils **/
import useAppContext from "../../hooks/useAppContext";
import { UserRightList } from "../../services/app";
import { Role } from "../../services/roles";
import RightGroupPanel from "./RightGroupPanel/RightGroupPanel";

interface RightSettingLayoutProps {
  checklist: Record<keyof UserRightList, boolean>;
  roles: Role[];
  selectedIndex: number;
  onItemCheck: (values: string, checked: boolean) => void;
  onRoleSelect: (index: number) => void;
  onSaveRights: () => void;
}

const RightSettingLayout: React.FC<RightSettingLayoutProps> = ({
  checklist,
  roles,
  selectedIndex,
  onItemCheck,
  onRoleSelect,
  onSaveRights,
}) => {
  const { hasRight } = useAppContext();
  const { t } = useTranslation();

  const rightGroups: {
    title: string;
    hide?: boolean;
    items: { title: string; value: keyof UserRightList }[];
  }[] = [
    {
      title: t("rightSetting.AlertRights"),
      items: [
        {
          title: t("rightSetting.CreateAlert"),
          value: "alert-create",
        },
        {
          title: t("app.common.DistributeAlert"),
          value: "alert-distribute",
        },
        {
          title: t("rightSetting.ReceiveAlert"),
          value: "alert-receive",
        },
        {
          title: t("rightSetting.AcknowledgeAlert"),
          value: "alert-acknowledge",
        },
      ],
    },
    {
      title: t("app.common.ManageRoles"),
      items: [
        {
          title: t("rightSetting.ViewRole"),
          value: "role-read",
        },
        {
          title: t("rightSetting.CreateRole"),
          value: "role-create",
        },
        {
          title: t("app.common.EditRole"),
          value: "role-update",
        },
        {
          title: t("rightSetting.AssignRole"),
          value: "role-assign",
        },
        {
          title: t("app.common.DeleteRole"),
          value: "role-delete",
        },
      ],
    },
    {
      title: t("app.common.AccessRights"),
      items: [
        {
          title: t("rightSetting.ViewAccess"),
          value: "right-read",
        },
        {
          title: t("rightSetting.EditAccess"),
          value: "right-update",
        },
      ],
    },
  ];

  return (
    <>
      <NxpHeader
        titleContent={t("app.common.AccessRights")}
        actionContent={
          selectedIndex !== -1 && (
            <Row gutter={10}>
              {hasRight("right-update") && (
                <Col>
                  <NxpButton color="primary" onClick={onSaveRights}>
                    {t("app.common.Save")}
                  </NxpButton>
                </Col>
              )}
            </Row>
          )
        }
      />
      <Row>
        <Col span={6}>
          {roles.map((role, index) => (
            <div>
              <NxpButton
                type="link"
                style={
                  selectedIndex === index
                    ? {}
                    : { color: "rgba(62, 63, 66, 1)", fontWeight: "normal" }
                }
                onClick={() => onRoleSelect(index)}
              >
                {role.name}
              </NxpButton>
            </div>
          ))}
        </Col>
        <Col span={18}>
          {selectedIndex !== -1 && (
            <>
              <AppFakeTableHeader
                items={[
                  { name: " ", span: 1 },
                  { name: t("rightSetting.Rights"), span: 22 },
                  { name: t("rightSetting.Allow"), span: 1 },
                ]}
                padding={8}
              />
              {rightGroups
                .filter((rightGroup) => !rightGroup.hide)
                .map((rightGroup) => (
                  <RightGroupPanel
                    title={rightGroup.title}
                    items={
                      rightGroup.items as {
                        title: string;
                        value: keyof UserRightList;
                      }[]
                    }
                    checklist={checklist}
                    onItemCheck={onItemCheck}
                  />
                ))}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default RightSettingLayout;
