import { apiRequest, ServiceConfig } from "../utils/backend";

export interface Entity {
  id: string;
  name: string;
  code: string; // added for CPCS-976
}

export interface RegisterType {
  value: string;
  label: string;
}

export interface User {
  id: string;
  entityId?: string;
  name: string;
  email: string;
}

export interface UserRightList {
  "alert-create": UserRightListItem[];
  "alert-distribute": UserRightListItem[];
  "alert-receive": UserRightListItem[];
  "alert-acknowledge": UserRightListItem[];

  "role-read": UserRightListItem[];
  "role-create": UserRightListItem[];
  "role-update": UserRightListItem[];
  "role-assign": UserRightListItem[];
  "role-delete": UserRightListItem[];

  "right-read": UserRightListItem[];
  "right-update": UserRightListItem[];
}

export declare interface UserRightListItem {
  source: "envar" | "module" | "team";
  role?: number;
  entity?: string;
  team?: number;
}

export const getEntityInfo = async (
  entityId: string,
  serviceConfig: ServiceConfig
): Promise<Entity> => {
  return await apiRequest<Entity>(`/entities/${entityId}`, serviceConfig).then(
    (entity) => ({
      ...entity,
    })
  );
};

export const getUserRightList = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<UserRightList>(
    `/entities/${entityId}/user-right-list`,
    serviceConfig
  );
};

export const getUsers = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<User[]>(`/entities/${entityId}/users`, serviceConfig);
};

export const getRoles = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<User[]>(`/entities/${entityId}/roles`, serviceConfig);
};

export const getMe = async (entityId: string, serviceConfig: ServiceConfig) => {
  return await apiRequest<User>(
    `/entities/${entityId}/users/me`,
    serviceConfig
  );
};

export interface CPCSDictionary {
  id: number;
  entity_id: number;
  is_deleted: boolean;
  name: string;
  code: string;
  created_at: Date;
  updated_at: Date;
  description: string;
  type: string;
  dictionaries: CPCSDictionaryItem[];
}

export interface CPCSDictionaryItem {
  name: string;
  description: string;
  code: string;
  children: CPCSDictionaryItem[];
}

export interface UserPreference {
  id: string;
  userId: string;
  application: string;
  parameter: string;
  value: string;
}
export type AddUserPreference = Omit<UserPreference, "id" | "userId">;

export const getCPCSDictionaryList = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<CPCSDictionary[]>(
    `/entity/entities/${serviceConfig.entityId}/categories/own`,
    serviceConfig
  );
};

export const getCPCSDictionary = async (
  dictionaryKey: string,
  serviceConfig: ServiceConfig
) => {
  try {
    return await apiRequest<CPCSDictionary>(
      `/entity/entities/${serviceConfig.entityId}/categories/${dictionaryKey}`,
      serviceConfig
    );
  } catch (ex) {
    console.error(ex);
  }
};

export const getMyUserPreference = async (serviceConfig: ServiceConfig) => {
  return apiRequest<UserPreference[]>(
    `${serviceConfig.qcApiServiceApiBaseUrl}/user-preference/me`,
    serviceConfig
  );
};
export const createMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<UserPreference>(
    `${serviceConfig.qcApiServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(userPreference),
    }
  );
};
export const patchMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<any>(
    `${serviceConfig.qcApiServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(userPreference),
    }
  );
};
