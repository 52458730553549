import React from "react";

import { Entity, User, UserRightList } from "../../../services/app";
import { Role } from "../../../services/roles";

export interface AppContextCache {
  activeEntity?: Entity;
  activeUser?: User;
  userRightList?: UserRightList;
  users: User[];
  roles: Role[];
  language?: string;
}

type AppContextType = AppContextCache & {
  onAppContextCacheUpdate: React.Dispatch<
    React.SetStateAction<AppContextCache>
  >;
  onAppContextCacheItemUpdate: <K extends keyof AppContextCache>(
    stateItem: K,
    itemValue: AppContextCache[K]
  ) => void;
  hasRight: (rightType: keyof UserRightList) => boolean;
};

const AppContext = React.createContext<AppContextType>({} as AppContextType);

export default AppContext;
