import { Attachment } from "./file";
import { Distribution } from "./distributions";
import { User } from "./app";
import { apiRequest, ServiceConfig } from "../utils/backend";

export interface Alert {
  attachments?: Attachment[];
  code?: string;
  createTime?: string;
  creator?: User;
  description?: string;
  distributionTime?: string;
  distributions?: Distribution[];
  entityId?: string;
  fileIds?: number[];
  id?: number;
  subject?: string;
}

export const getAlerts = async (
  entityId: string,
  serviceConfig: ServiceConfig,
  query?: string
) => {
  return await apiRequest(
    `/entities/${entityId}/alerts` + query,
    serviceConfig
  );
};

export const createAlert = async (
  entityId: string,
  alert: Alert,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(`/entities/${entityId}/alerts`, serviceConfig, {
    method: "POST",
    body: JSON.stringify({
      attachments: alert.fileIds,
      subject: alert.subject,
      description: alert.description,
    }),
  });
};

export const getAlert = async (
  entityId: string,
  alertId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/alerts/${alertId}`,
    serviceConfig
  );
};

export const updateAlert = async (
  entityId: string,
  alertId: number,
  alert: Alert,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/alerts/${alertId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        attachments: alert.fileIds,
        subject: alert.subject,
        description: alert.description,
      }),
    }
  );
};
