/** Packages **/
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { notify } from "@nexploretechnology/nxp-ui";

/** Services-Utils **/
import useAppContext from "../../hooks/useAppContext";
import { UserRightList } from "../../services/app";
import { getRoles, Role, updateRoleRights } from "../../services/roles";
/** Components **/
import RightSettingLayout from "./RightSettingLayout";

interface RightSettingContainerProps {}

const RightSettingContainer: React.FC<RightSettingContainerProps> = () => {
  const [checklist, setChecklist] = useState<
    Record<keyof UserRightList, boolean>
  >({} as Record<keyof UserRightList, boolean>);
  const [roles, setRoles] = useState<Role[]>([] as Role[]);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const { serviceConfig } = useAppContext();
  const { t } = useTranslation();
  const routeParam = useParams<{
    entityId: string;
  }>();

  const handleItemCheck = (values: string, checked: boolean) => {
    setChecklist((prev) => ({
      ...prev,
      [values]: checked,
    }));
  };

  const handleSaveRights = () => {
    const role = roles[selectedIndex];

    const rightTypes: keyof UserRightList[] = Object.keys(checklist).filter(
      (key) => !!checklist[key as keyof UserRightList]
    ) as unknown as keyof UserRightList[];

    if (!routeParam.entityId) return;

    updateRoleRights(routeParam.entityId, role.id, rightTypes, serviceConfig)
      .then((role) => {
        setRoles(
          roles.map((oldRole) => (oldRole.id === role.id ? role : oldRole))
        );
        notify.success(
          `${t("rightSetting.notifySuccess.UpdateRoles")} ${role.name}`
        );
      })
      .catch((err) =>
        notify.error(
          `${t("rightSetting.notifyError.UpdateRoles")}${role.name} : ${err}`
        )
      );
  };

  const handleRoleSelect = (index: number) => {
    setSelectedIndex(index);

    const newChecklist = {} as Record<keyof UserRightList, boolean>;
    for (const right of roles[index].rights) {
      newChecklist[right.type] = true;
    }
    setChecklist(newChecklist);
  };

  useEffect(() => {
    if (!routeParam.entityId) return;

    getRoles(routeParam.entityId, serviceConfig).then((roles) => {
      if (roles)
        roles.sort((a: Role, b: Role) => {
          if (a?.name > b.name) return 1;
          if (a?.name < b.name) return -1;
          return 0;
        });
      setRoles(roles);
    });
  }, [routeParam.entityId, serviceConfig]);

  return (
    <RightSettingLayout
      roles={roles}
      selectedIndex={selectedIndex}
      checklist={checklist}
      onRoleSelect={handleRoleSelect}
      onItemCheck={handleItemCheck}
      onSaveRights={handleSaveRights}
    />
  );
};

export default RightSettingContainer;
