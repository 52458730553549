import { AddRoleForm } from "../pages/RoleSetting/AddRoleModal/AddRoleModal";
import { EditRoleForm } from "../pages/RoleSetting/EditRoleModal/EditRoleModal";
import { apiRequest, ServiceConfig } from "../utils/backend";
import { User, UserRightList } from "./app";

export interface Role {
  assignees: User[];
  code: string;
  description: string;
  entityId: string;
  id: number;
  name: string;
  rights: Right[];
}

export interface Right {
  id: number;
  type: keyof UserRightList;
  role?: Role;
}

export const createRole = async (
  entityId: string,
  addRoleForm: AddRoleForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(`/entities/${entityId}/roles`, serviceConfig, {
    method: "POST",
    body: JSON.stringify(addRoleForm),
  });
};

export const getRoles = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Role[]>(`/entities/${entityId}/roles`, serviceConfig);
};

export const editRole = async (
  entityId: string,
  roleId: number,
  editRoleForm: EditRoleForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/roles/${roleId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(editRoleForm),
    }
  );
};

export const deleteRole = async (
  entityId: string,
  roleId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/roles/${roleId}`,
    serviceConfig,
    { method: "DELETE" }
  );
};

export const updateRoleAssignees = async (
  entityId: string,
  roleId: number,
  userIds: string[],
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Role>(
    `/entities/${entityId}/roles/${roleId}/assignees`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify({ userIds }),
    }
  );
};

export const batchAddRoleAssignees = async (
  entityId: string,
  roleId: number,
  userIds: number[],
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Role>(
    `/entities/${entityId}/roles/${roleId}/assignees`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({ userIds }),
    }
  );
};

export const updateRoleRights = async (
  entityId: string,
  roleId: number,
  rightTypes: keyof UserRightList[],
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Role>(
    `/entities/${entityId}/roles/${roleId}/rights`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify({ rightTypes }),
    }
  );
};
