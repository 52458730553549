import React from "react";
import { Route, RouteProps } from "react-router-dom";

import {
  notify,
  NxpAuthSpinner,
  NxpLayoutGlobal,
} from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";

import useAppContext from "../../../hooks/useAppContext";
import AppLayout from "../AppLayout/AppLayout";
import useRouteAppContextUpdate from "./useRouteAppContextUpdate";

interface AppProtectedRouteProps extends RouteProps {
  requireActiveEntity?: boolean;
}

const AppProtectedRoute: React.FC<AppProtectedRouteProps> = ({
  requireActiveEntity,
  children,
  ...restProps
}) => {
  const { keycloak } = useKeycloak();
  const appContext = useAppContext();
  useRouteAppContextUpdate();

  if (
    keycloak?.authenticated &&
    (!requireActiveEntity || appContext.activeEntity)
  ) {
    return (
      <Route {...restProps}>
        <AppLayout>{children}</AppLayout>
      </Route>
    );
  }

  setTimeout(() => {
    if (!keycloak?.authenticated) {
      notify.error(
        "Cannot connect to authentication server. Please try refreshing your browser or contact support."
      );
    }
  }, 15000);
  return (
    <NxpLayoutGlobal>
      <NxpAuthSpinner />
    </NxpLayoutGlobal>
  );
};

export default AppProtectedRoute;
