/** Packages **/
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

/** Services-Utils **/
import useAppContext from "../../../hooks/useAppContext";

interface BatchAssignModalProps {
  show: boolean;
  onBatchAssign: (form: BatchAssignForm) => Promise<boolean>;
  onClose: () => void;
}

export interface BatchAssignForm {
  userIds: number[];
}

const formSchema = yup.object().shape({
  userIds: yup.array().nullable(),
});

const emptyForm = {
  userIds: [],
};

const BatchAssignModalLayout: React.FC<BatchAssignModalProps> = ({
  show,
  onBatchAssign,
  onClose,
}) => {
  const [form, setForm] = useState<BatchAssignForm>(emptyForm);
  const [saving, setSaving] = useState<boolean>(false);

  const { users } = useAppContext();
  const { t } = useTranslation();

  const formItems: NxpFormGridItemProps<BatchAssignForm>[] = [
    {
      controlType: "selectUser",
      label: t("app.common.Users"),
      itemFieldName: "userIds",
      span: 24,
      controlProps: {
        allowClear: true,
        mode: "multiple",
        options: users.map((user) => ({ id: "" + user.id, name: user.name })),
        filterOption: (input, option: any) => {
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
      },
    },
  ];

  const handleSaveValidated = async () => {
    setSaving(true);
    onBatchAssign(form).then((success) => {
      if (success) {
        onClose();
      } else {
        setSaving(false);
      }
    });
  };

  const [validationError, , , saveWithValidate] =
    useYupValidate<BatchAssignForm>(form, formSchema, handleSaveValidated);

  const handleCloseButton = () => {
    if (saving) return;
    onClose();
  };

  const handleFormStateChange = (
    fieldName: keyof typeof form,
    value: unknown
  ) => {
    if (fieldName === "userIds") {
      setForm((prevState: BatchAssignForm) => ({
        ...prevState,
        userIds: value as number[],
      }));
    } else {
      setForm((prevState: BatchAssignForm) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const handleSaveButton = () => {
    saveWithValidate(undefined);
  };

  useEffect(() => {
    return () => {
      setForm(emptyForm);
      setSaving(false);
    };
  }, [show]);

  return (
    <NxpModal
      width="medium"
      title={t("roleSetting.BatchAssign")}
      visible={show}
      onCancel={handleCloseButton}
      footer={
        <>
          <NxpButton onClick={handleCloseButton}>
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton onClick={handleSaveButton} loading={saving}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={form}
        onFormStateChange={handleFormStateChange}
      />
    </NxpModal>
  );
};

export default BatchAssignModalLayout;
