/** Packages **/
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  CalendarOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  DateFormatPattern,
  NxpButton,
  NxpFormItem,
  NxpHeader,
  NxpTableLazy,
} from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";

/** Components **/
import AlertModal from "../../components/AlertModal";
import { ModalState } from "../../components/AlertModal/AlertModal";
/** Services-Utils **/
import useAppContext from "../../hooks/useAppContext";
import { Alert } from "../../services/alerts";
import { AlertsContext } from "./AlertsContainer";

interface AlertsLayoutProps {}

const useStyles = createUseStyles(() => ({
  date: {
    alignItems: "center",
    "& .anticon-calendar": {
      padding: "0px  5px 0px 0px",
    },
    display: "flex",
  },
}));

const AlertsLayout: React.FC<AlertsLayoutProps> = () => {
  const {
    alerts,
    lazyLoadStatus,
    showAddRoleModal,
    onAddAlert,
    onInputChange,
    onLazyLoad,
    onRefresh,
    onRowClick,
    onSortingChange,
    setShowAddRoleModal,
  } = useContext(AlertsContext);

  const appContext = useAppContext();
  const { hasRight } = appContext;
  const classes = useStyles();
  const { t } = useTranslation();

  const columns: ColumnProps<Alert>[] = [
    {
      title: t("app.common.Subject"),
      dataIndex: "subject",
      width: 100,
      sorter: true,
    },
    {
      title: t("app.common.AlertNumber"),
      dataIndex: "code",
      width: 100,
      sorter: true,
    },
    {
      title: t("app.common.CreatedBy"),
      dataIndex: ["creator", "name"],
      width: 100,
      sorter: true,
    },
    {
      title: t("app.common.CreatedOn"),
      dataIndex: "createTime",
      width: 100,
      render: (_: any, alert: Alert) => {
        return (
          <div className={classes.date}>
            <CalendarOutlined />
            <NxpFormItem
              editing={false}
              controlType="input"
              controlProps={{
                value: moment(Number(alert.createTime!)).format(
                  DateFormatPattern.date
                ),
              }}
            />
          </div>
        );
      },
      sorter: true,
    },
    {
      title: t("app.common.DistributedOn"),
      dataIndex: "distributionTime",
      width: 100,
      render: (_: any, alert: Alert) => {
        return alert.distributionTime !== "0" ? (
          <div className={classes.date}>
            <CalendarOutlined />
            <NxpFormItem
              editing={false}
              controlType="input"
              controlProps={{
                value: moment(Number(alert.distributionTime!)).format(
                  DateFormatPattern.date
                ),
              }}
            />
          </div>
        ) : (
          <NxpFormItem
            editing={false}
            controlType="input"
            controlProps={{
              value: "N/A",
            }}
          />
        );
      },
      sorter: true,
    },
  ];

  return (
    <>
      <NxpHeader
        titleContent={t("app.common.Alerts")}
        actionContent={
          <Row gutter={10}>
            <Col>
              <NxpFormItem
                controlType={"input"}
                controlProps={{
                  onChange: (e) => onInputChange(e.target.value),
                  prefix: <SearchOutlined />,
                  placeholder: t("app.common.Search"),
                }}
              />
            </Col>
            {hasRight("alert-create") && (
              <Col>
                <NxpButton
                  color="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setShowAddRoleModal(true)}
                >
                  {t("app.common.NewAlert")}
                </NxpButton>
                <AlertModal
                  modalState={ModalState.add}
                  show={showAddRoleModal}
                  onAddAlert={onAddAlert}
                  onClose={() => setShowAddRoleModal(false)}
                  onRefresh={() => onRefresh()}
                />
              </Col>
            )}
          </Row>
        }
      />
      <NxpTableLazy
        columns={columns as any}
        dataSource={alerts as any}
        lazyLoadStatus={lazyLoadStatus}
        pagination={false}
        useDefaultHeight={-64}
        onChange={(pagination, filters, sorter: any) => {
          onSortingChange!(sorter.field, sorter.order);
        }}
        onLazyLoad={onLazyLoad}
        onRow={(alert: { id: string }) => ({
          onClick: () => {
            if (onRowClick) {
              onRowClick(alert.id);
            }
          },
        })}
      ></NxpTableLazy>
    </>
  );
};

export default AlertsLayout;
