/** Packages **/
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import {
  LazyLoadStatus,
  notify,
  useDebouncedValue,
} from "@nexploretechnology/nxp-ui";
import { isEmpty } from "lodash";

/** Services-Utils **/
import useAppContext from "../../hooks/useAppContext";
import { Alert, getAlerts } from "../../services/alerts";
/** Components **/
import AlertsLayout from "./AlertsLayout";

interface AlertsContextType {
  alerts: Alert[] | undefined;
  filter: string;
  lazyLoadStatus: LazyLoadStatus;
  showAddRoleModal: boolean;
  onAddAlert: (alert: Alert) => void;
  onInputChange: (value: string) => void;
  onLazyLoad: () => void;
  onRefresh: () => void;
  onRowClick: (alertId: string) => void;
  onSortingChange?: (sortBy: string, order: "ASC" | "DESC") => void;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  setShowAddRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AlertsContext = React.createContext<AlertsContextType>(
  {} as AlertsContextType
);

interface AlertsContainerProps {}

const QUERY_SIZE: number = 50; /** Request max distribution row size. **/

const AlertsContainer: React.FC<AlertsContainerProps> = () => {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [lazyLoadStatus, setLazyLoadStatus] = useState(LazyLoadStatus.ready);
  const [order, setOrder] = useState<"ASC" | "DESC">("DESC");
  const [page, setPage] = useState<number>(1);
  const [showAddRoleModal, setShowAddRoleModal] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<undefined | string>("code");

  const appContext = useAppContext();
  const { serviceConfig, hasRight } = appContext;
  const history = useHistory();
  const routeParam = useParams<{
    entityId: string;
  }>();
  const { t } = useTranslation();

  const searchValueDebounced = useDebouncedValue(filter, 500, [filter]);

  const handleAddAlert = (alert: Alert) => {
    alerts?.unshift(alert);
    setAlerts([...alerts]);
  };

  const handleInputChange = (value: string) => {
    /** We want to reset the Alerts array and page state whenever input changes. **/
    setAlerts([]);
    setPage(1);
    setFilter(value);
    setLazyLoadStatus(LazyLoadStatus.loading);
  };

  const handleLazyLoad = () => {
    setPage(page + 1);
  };

  const handleRowClick = (alertId: string) => {
    history.push(`/entities/${routeParam?.entityId}/alerts/${alertId}`);
  };

  const handleSortingChange = (field: any, order: any) => {
    if (order) {
      /** Mapping of dataIndex of column attribute to backend query sortBy string. **/
      if (field.includes("subject")) field = "subject";
      if (field.includes("code")) field = "code";
      if (field.includes("name")) field = "creator";
      if (field.includes("createTime")) field = "createTime";
      if (field.includes("acknowledgeTime")) field = "acknowledgementTime";

      /** We want to reset the Alerts array and page state while setting the order and field. **/
      setAlerts([]);
      setPage(1);
      setOrder(order === "descend" ? "DESC" : "ASC");
      setSortBy(field);
    }
  };

  const handleRefresh = () => {
    fetch();
  };

  const fetch = useCallback(async () => {
    let query = `?page=${page}&size=${QUERY_SIZE}&sortBy=${sortBy}&sortDirection=${order}`;

    if (!isEmpty(filter)) query += `&keyword=${filter}`;

    try {
      await getAlerts(routeParam?.entityId, serviceConfig, query).then(
        (alerts: Alert[]) => {
          setAlerts((prevState) => {
            setLazyLoadStatus(LazyLoadStatus.ready);

            /** Case that backend returns no more new items. **/
            if (alerts.length === 0) {
              setLazyLoadStatus(LazyLoadStatus.noMoreItem);
            }

            /** Case that backend cannot find any matching search results. **/
            if (alerts.length === 0 && page === 1 && !isEmpty(filter))
              return [];

            /** Case that there's existing data but page state incremented by 1, but we don't want to purge array. **/
            if (alerts.length === 0 && page !== 1 && !isEmpty(filter))
              return prevState;

            /** Optimization purposes by mapping only certain key values from the response array. **/
            return [
              ...prevState.concat(
                alerts.map(
                  ({
                    id,
                    subject,
                    code,
                    creator,
                    createTime,
                    distributionTime,
                  }) => ({
                    id,
                    key: id,
                    subject,
                    code,
                    creator,
                    createTime,
                    distributionTime,
                  })
                )
              ),
            ];
          });
        }
      );
    } catch (error: any) {
      if (!hasRight("alert-create") || !hasRight("alert-distribute")) return;
      notify.error(`${t("alerts.notifyError.RetrieveAlerts")}: ${error}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order,
    page,
    routeParam?.entityId,
    searchValueDebounced,
    serviceConfig,
    sortBy,
  ]);

  /** Initialization of component and update component with reference to CallBack. **/
  useEffect(() => {
    fetch();
  }, [fetch]);

  /** Update component with reference to searchValueDebounced CallBack. **/
  useEffect(() => {}, [searchValueDebounced]);

  return (
    <AlertsContext.Provider
      value={{
        alerts,
        filter,
        lazyLoadStatus,
        showAddRoleModal,
        onAddAlert: handleAddAlert,
        onInputChange: handleInputChange,
        onLazyLoad: handleLazyLoad,
        onRefresh: handleRefresh,
        onRowClick: handleRowClick,
        onSortingChange: handleSortingChange,
        setFilter,
        setShowAddRoleModal,
      }}
    >
      {(hasRight("alert-create") || hasRight("alert-distribute")) && (
        <AlertsLayout />
      )}
    </AlertsContext.Provider>
  );
};

export default AlertsContainer;
