import React, { useCallback, useState } from "react";
import { UserRightList } from "../../../services/app";
import AppContext, { AppContextCache } from "./AppContext";

interface AppContextProviderProps {
  initAppContextCache?: AppContextCache;
  userRightList?: UserRightList;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  initAppContextCache,
  children,
}) => {
  const [appContextCache, setAppContextCache] = useState<AppContextCache>(
    initAppContextCache || {
      users: [],
      roles: [],
    }
  );

  const handleAppContextCacheItemUpdate = useCallback(
    <K extends keyof AppContextCache>(
      cacheItem: K,
      itemValue: AppContextCache[K]
    ) => {
      setAppContextCache((prevState) => ({
        ...prevState,
        [cacheItem]: itemValue,
      }));
    },
    []
  );

  const hasRight = (rightType: keyof UserRightList) => {
    if (!appContextCache.userRightList) return false;
    if (!appContextCache.userRightList[rightType]?.length) return false;

    const { userRightList } = appContextCache;

    for (const right of userRightList[rightType]) {
      if (right.source === "module") {
        return true;
      }
    }
    return false;
  };

  return (
    <AppContext.Provider
      value={{
        ...appContextCache,
        onAppContextCacheUpdate: setAppContextCache,
        onAppContextCacheItemUpdate: handleAppContextCacheItemUpdate,
        hasRight,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
