/** Packages **/
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { NxpButton, NxpSelectUser } from "@nexploretechnology/nxp-ui";
import { Card, Checkbox, Col, Dropdown, Menu, Modal, Row } from "antd";

/** Services-Utils **/
import useAppContext from "../../../hooks/useAppContext";
import { Role } from "../../../services/roles";
/** Components **/
import EditRoleModal, { EditRoleForm } from "../EditRoleModal/EditRoleModal";

interface RoleAssigneeListProps {
  originalUserIds: string[];
  role: Role;
  selected: boolean;
  userIds: string[];
  onAssigneeChange: (role: Role, userIds: string[]) => void;
  onDelete: (role: Role) => Promise<boolean>;
  onEditRole: (form: EditRoleForm) => Promise<boolean>;
  onRoleSelect: (value: boolean) => void;
}

const useStyles = createUseStyles(() => ({
  card: { marginBottom: 10 },
  selector: { width: "100%" },
}));

const RoleAssigneeList: React.FC<RoleAssigneeListProps> = ({
  role,
  selected,
  userIds = [],
  onAssigneeChange,
  onDelete,
  onEditRole,
  onRoleSelect,
}) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { users, hasRight } = useAppContext();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDelete = () => {
    Modal.confirm({
      title: `${t("app.common.DeleteRole")}?`,
      icon: <ExclamationCircleOutlined />,
      okText: t("app.common.Confirm"),
      cancelText: t("app.common.Cancel"),
      content: t("app.common.DeleteRolePrompt"),
      onOk: async () => {
        await onDelete(role);
      },
      onCancel: () => {},
    });
  };

  const handleValueChange = (value: string | string[]) => {
    if (typeof value == "string") {
      value = [value];
    }
    onAssigneeChange(role, value);
  };

  return (
    <Card className={classes.card}>
      <Row>
        <Col span={1}>
          {hasRight("role-assign") && (
            <Checkbox
              checked={selected}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                onRoleSelect(e.target.checked);
              }}
            />
          )}
        </Col>
        <Col span={5}>{role.name}</Col>
        <Col span={17}>
          <NxpSelectUser
            disabled={!hasRight("role-assign")}
            className={classes.selector}
            mode="multiple"
            allowClear={true}
            options={users?.map((user) => ({
              name: user?.name,
              id: "" + user?.id,
            }))}
            filterOption={(input, option: any) => {
              return (
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            value={userIds.map((userId) => "" + userId)}
            onChange={handleValueChange}
          ></NxpSelectUser>
        </Col>
        <Col span={1}>
          {(hasRight("role-update") || hasRight("role-delete")) && (
            <Dropdown
              overlay={
                <Menu
                  onClick={(menuInfo) => {
                    if (menuInfo.key === "edit") setShowEditModal(true);
                    if (menuInfo.key === "delete") handleDelete();
                  }}
                >
                  {hasRight("role-update") && (
                    <Menu.Item key="edit">{t("app.common.Edit")}</Menu.Item>
                  )}
                  {hasRight("role-delete") && (
                    <Menu.Item key="delete">{t("app.common.Delete")}</Menu.Item>
                  )}
                </Menu>
              }
              placement="bottomRight"
            >
              <NxpButton type="text">
                <EllipsisOutlined />
              </NxpButton>
            </Dropdown>
          )}
        </Col>
      </Row>
      <EditRoleModal
        show={showEditModal}
        role={role}
        onEditRole={onEditRole}
        onClose={() => setShowEditModal(false)}
      />
    </Card>
  );
};

export default RoleAssigneeList;
