import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { Nxp404 } from "@nexploretechnology/nxp-ui";

import AlertPage from "../../../pages/AlertPage";
import Alerts from "../../../pages/Alerts";
import AlertsReceived from "../../../pages/AlertsReceived";
import RightSetting from "../../../pages/RightSetting";
import RoleSetting from "../../../pages/RoleSetting";
import AppProtectedRoute from "./AppProtectedRoute";

interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to={`/entities/:entityId/alerts`} />
        </Route>
        <AppProtectedRoute exact path={`/entities/:entityId/alerts`}>
          <Alerts />
        </AppProtectedRoute>
        <AppProtectedRoute exact path={`/entities/:entityId/alerts-received`}>
          <AlertsReceived />
        </AppProtectedRoute>
        <AppProtectedRoute exact path={`/entities/:entityId/alerts/:alertId`}>
          <AlertPage />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`/entities/:entityId/alerts-received/:distributionId`}
        >
          <AlertPage />
        </AppProtectedRoute>
        <AppProtectedRoute exact path={`/entities/:entityId/role`}>
          <RoleSetting />
        </AppProtectedRoute>
        <AppProtectedRoute exact path={`/entities/:entityId/rights`}>
          <RightSetting />
        </AppProtectedRoute>
        <AppProtectedRoute>
          <Nxp404 />
        </AppProtectedRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
