/** Packages **/
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";

import { InboxOutlined, PaperClipOutlined } from "@ant-design/icons";
import {
  notify,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Checkbox, Image, Progress } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { UploadFile } from "antd/lib/upload/interface";
import * as yup from "yup";

/** Services-Utils **/
import useAppContext from "../../hooks/useAppContext";
import { Alert, createAlert, updateAlert } from "../../services/alerts";
import { sendFile } from "../../services/file";

export enum ModalState {
  add,
  edit,
}

interface AlertModalProps {
  editAlert?: Alert;
  modalState: ModalState;
  show: boolean;
  onAddAlert?: (alert: Alert) => void;
  onClose: () => void;
  onRefresh?: () => void;
}

let emptyAlert = {
  fileIds: [],
  subject: "",
  description: "",
};

const MAX_DESCRIPTION_CHAR: number = 1024;
const TIMEOUT_DURATION: number = 750;

const useStyles = createUseStyles(() => ({
  attachments: {
    display: "inline-block",
  },
  card: {
    display: "flex",
    margin: "0px 10px 0px 0px",
    "& > .ant-image": {
      border: "1px solid #C6C9CF",
      height: "100px",
      width: "100px",
      "& > .ant-image-img": {
        height: "100%",
        width: "100%",
      },
    },
    "& > a": {
      border: "1px solid #C6C9CF",
      fontSize: "30px",
      height: "100px",
      width: "100px",
      "& > .anticon": {
        color: "#67696E",
        margin: "35%",
      },
    },
    "& > p": {
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: "16px",
      margin: "0px",
      padding: "0px",
    },
  },
  cardFooter: {
    display: "flex",
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100px",
  },
  checkbox: {
    padding: "0px 5px 0px 0px",
    "& *.ant-checkbox-checked::after": {
      border: "none",
    },
  },
  descriptionCharText: { float: "right" },
  footer: {
    display: "flex",
    justifyContent: "right",
  },
  modal: {
    "& * label": {
      display: "block",
    },
  },
  removeText: {
    display: "flex",
    padding: "15px 0px 0px 0px",
    "& > h6": {
      fontSize: "13px",
      fontWeight: "500",
      padding: "0px 10px 0px 0px",
    },
  },
}));

const AddRoleModal: React.FC<AlertModalProps> = ({
  editAlert,
  modalState,
  show,
  onAddAlert,
  onClose,
  onRefresh,
}) => {
  const [alert, setAlert] = useState<Alert>(emptyAlert);
  const [fileList, setFileList] = useState<UploadFile[]>();
  const [percent, setPercent] = useState<number>();
  const [removeList, setRemoveList] = useState<number[]>([]);
  const [saving, setSaving] = useState<boolean>(false);

  const appContext = useAppContext();
  const { serviceConfig } = appContext;
  const routeParam = useParams<{
    entityId: string;
    alertId: string;
  }>();
  const classes = useStyles();
  const { t } = useTranslation();

  const alertSchema = yup.object().shape({
    subject: yup.string().required(t("alertModal.SubjectIsRequired")).trim(),
    description: yup
      .string()
      .required(t("alertModal.DescriptionIsRequired"))
      .max(MAX_DESCRIPTION_CHAR, t("alertModal.MaxCharacterReached"))
      .trim(),
  });

  const handleFileChange = (fileObject: UploadChangeParam<UploadFile<any>>) => {
    setFileList(fileObject.fileList);
  };

  const handleChange = (value: number, checked: boolean) => {
    if (checked === true && removeList?.includes(value)) return;
    if (checked === true && !removeList?.includes(value))
      setRemoveList([...removeList, value]);
    if (checked === false && removeList?.includes(value))
      setRemoveList(removeList.filter((item) => item !== value));
    if (checked === false && !removeList?.includes(value)) return;
  };

  const formItems: NxpFormGridItemProps<Alert>[] = [
    {
      controlType: "input",
      label: t("app.common.Subject"),
      itemFieldName: "subject",
      required: true,
      span: 12,
    },
    {
      controlType: "textarea",
      controlProps: { rows: 5 },
      label: (
        <>
          {t("app.common.Description")}
          <div className={classes.descriptionCharText}>
            {`(${t("alertModal.CharacterCount")}: ${
              alert.description?.length
            }/${MAX_DESCRIPTION_CHAR})`}
          </div>
        </>
      ),
      itemFieldName: "description",
      required: true,
      span: 24,
    },
    {
      controlType: "custom",
      label: t("app.common.Attachment"),
      itemFieldName: "custom",
      span: 24,
      customContent: (
        <>
          <Dragger
            name="file"
            multiple
            beforeUpload={() => false}
            fileList={fileList}
            onChange={handleFileChange}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t("alertModal.UploadText")}</p>
          </Dragger>
          {modalState === ModalState.edit && (
            <>
              <div className={classes.removeText}>
                {editAlert && editAlert?.attachments!.length > 0 && (
                  <h6>{t("alertModal.RemoveAttachments")}</h6>
                )}
                {removeList.length > 0 && (
                  <h6>{`( ${t("app.common.Selected")}: ${
                    removeList.length
                  })`}</h6>
                )}
              </div>
              {alert?.attachments?.map((attachment) => (
                <div className={classes.attachments}>
                  <div className={classes.card}>
                    {attachment.type?.includes("image") ? (
                      <Image src={attachment.originalUrl} />
                    ) : (
                      <a
                        href={attachment.originalUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PaperClipOutlined />
                      </a>
                    )}
                  </div>
                  <div className={classes.cardFooter}>
                    <Checkbox
                      className={classes.checkbox}
                      checked={removeList.includes(attachment.id!)}
                      value={attachment?.id}
                      onChange={(e) =>
                        handleChange(e.target.value, e.target.checked)
                      }
                    ></Checkbox>
                    <p>{attachment?.name}</p>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      ),
    },
  ];

  const handleSaveValidated = async () => {
    setSaving(true);

    let fileIds: number[] = [];
    let count: number = 0;

    fileList?.forEach((file) => {
      const blob = new Blob([file?.originFileObj!], {
        type: file.type,
      });
      const data = new FormData();
      data.append("file", blob, file.name);

      sendFile(routeParam?.entityId, serviceConfig, data).then((response) => {
        fileIds.push(response?.id!);
        count++;
        setPercent(Math.round((count / fileList.length) * 100));
        if (fileIds.length === fileList.length) {
          handleRequest(fileIds);
        }
      });
    });
    if (!fileList?.length) {
      handleRequest(fileIds); /* FileIds is an empty array anyways. */
    }
  };

  const handleRequest = async (fileIds: number[]) => {
    /** (Note): Although we can update Alert state with new fileIds, the mutated value won't be passed until next render. **/

    /** We need this timeout for the backend response to populate fileIds array, or else that array will be empty. **/
    setTimeout(() => {
      /** If the modal is currently creating. **/
      if (modalState === ModalState.add) {
        try {
          createAlert(
            routeParam?.entityId,
            { ...alert, fileIds: fileIds },
            serviceConfig
          ).then((_alert) => onAddAlert!(_alert));
          notify.success(t("alertModal.notifySuccess.CreateAlert"));
          setAlert(emptyAlert);
          setFileList([]);
        } catch (error: any) {
          notify.error(`${t("alertModal.notifyError.CreateAlert")}: ${error}`);
        }
      } /** If the modal is currently editing. **/ else if (
        modalState === ModalState.edit
      ) {
        try {
          updateAlert(
            routeParam?.entityId,
            alert.id!,
            {
              ...alert,
              fileIds: fileIds.concat(
                alert.attachments
                  ?.filter((attachment) => attachment?.id!)
                  .map((item) => item.id!)
                  .filter((item) => !removeList.includes(item))!
              ),
            },
            serviceConfig
          ).then((_alert) => {
            setAlert(_alert);
            onRefresh!();
          });
          notify.success(t("alertModal.notifySuccess.UpdateAlert"));
        } catch (error: any) {
          notify.error(`${t("alertModal.notifyError.UpdateAlert")}: ${error}`);
        }
      }
      onClose();
      setSaving(false);
    }, TIMEOUT_DURATION);
  };

  const [validationError, , , saveWithValidate] = useYupValidate<Alert>(
    alert,
    alertSchema,
    handleSaveValidated
  );

  const handleCloseButton = () => {
    if (saving) return;

    /** Reset Alert modal when user closes it. **/
    setAlert(emptyAlert);
    setFileList([]);
    onClose();
  };

  const handleFormStateChange = (
    fieldName: keyof typeof alert,
    value: unknown
  ) => {
    setAlert((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSaveButton = () => {
    saveWithValidate(undefined);
  };

  useEffect(() => {}, [percent]);

  useEffect(() => {
    if (editAlert) setAlert(editAlert);
    setSaving(false);
    setPercent(0);
    setFileList([]);
    setRemoveList([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <NxpModal
      className={classes.modal}
      width="small"
      title={
        modalState === ModalState.add
          ? t("alertModal.AddNewAlert")
          : t("alertModal.EditAlert")
      }
      visible={show}
      showMandatoryLabel
      onCancel={handleCloseButton}
      footer={
        <div className={classes.footer}>
          {saving && fileList!.length > 0 && <Progress percent={percent} />}
          <NxpButton onClick={handleSaveButton} loading={saving}>
            {t("app.common.Save")}
          </NxpButton>
        </div>
      }
    >
      <NxpFormGrid
        formItems={formItems}
        formState={alert}
        validationError={validationError}
        onFormStateChange={handleFormStateChange}
      />
    </NxpModal>
  );
};

export default AddRoleModal;
