/** Packages **/
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useParams } from "react-router-dom";

import {
  CalendarOutlined,
  EditOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import {
  DateFormatPattern,
  notify,
  NxpButton,
  NxpButtonWithConfirm,
  NxpHeader,
  NxpPanel,
  NxpTable,
} from "@nexploretechnology/nxp-ui";
import { Col, Image, Row } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";

/** Components **/
import AlertModal, { ModalState } from "../../components/AlertModal/AlertModal";
import useAppContext from "../../hooks/useAppContext";
/** Services-Utils **/
import { Alert } from "../../services/alerts";
import { createDistribution, Distribution } from "../../services/distributions";
import { DistributionRow } from "./AlertPageContainer";
import DistributeAlertModal from "./DistributeAlertModal";
import SignatureModal from "./SignatureModal";

const TIMEOUT_DURATION: number = 750;

interface AlertPageLayoutProps {
  alert: Alert;
  columns: ColumnProps<DistributionRow>[];
  distribution: Distribution;
  distributionRows: DistributionRow[] | undefined;
  selectedDistributionRow: DistributionRow;
  showDistributeAlertModal: boolean;
  showEditRoleModal: boolean | undefined;
  showSignatureModal: boolean;
  onRefresh: () => void;
  onRowClick: (distributionRow: DistributionRow) => void;
  setShowDistributeAlertModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEditRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSignatureModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = createUseStyles(() => ({
  attachments: {
    display: "inline-block",
  },
  card: {
    display: "flex",
    margin: "0px 10px 0px 0px",
    "& > .ant-image": {
      border: "1px solid #C6C9CF",
      height: "100px",
      width: "100px",
      "& > .ant-image-img": {
        height: "100%",
        width: "100%",
      },
    },
    "& > a": {
      border: "1px solid #C6C9CF",
      fontSize: "30px",
      height: "100px",
      width: "100px",
      "& > .anticon": {
        color: "#67696E",
        margin: "35%",
      },
    },
    "& > p": {
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: "16px",
      margin: "0px",
      padding: "0px",
    },
  },
  cardFooter: {
    display: "flex",
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100px",
  },
  date: {
    alignItems: "center",
    display: "flex",
    "& .anticon-calendar": {
      padding: "0px  5px 0px 0px",
    },
    "& > p": {
      margin: "0px 0px 0px 0px",
    },
  },
  description: {
    wordWrap: "break-word",
  },
  signature: {
    "& * .ant-collapse-extra": {
      display: "inline-block",
      float: "none !important",
      "& > h3": {
        fontSize: "0.8rem",
        fontWeight: "400",
        margin: "0px 0px 0px 0px",
        padding: "0px 0px 0px 30px",
      },
    },
  },
  general: {
    "& * h6": {
      color: "rgb(138, 153, 153)",
      fontSize: "0.7rem",
      textTransform: "uppercase",
    },
    "& * .ant-row:first-child": {
      padding: "0px 0px 10px 0px",
      "& > .ant-col:not(:last-child)": {
        borderRight: "1px solid #EAEDF3",
        margin: "0px 30px 0px 0px",
      },
    },
    "& * .ant-row:not(:first-child)": {
      padding: "15px 0px 10px 0px",
    },
    "& * .ant-row:not(:last-child)": {
      borderBottom: "1px solid #EAEDF3",
    },
  },
  table: {
    padding: "5px 0px 0px 0px",
  },
}));

const AlertPageLayout: React.FC<AlertPageLayoutProps> = ({
  alert,
  columns,
  distribution,
  distributionRows,
  selectedDistributionRow,
  showDistributeAlertModal,
  showEditRoleModal,
  showSignatureModal,
  onRefresh,
  onRowClick,
  setShowDistributeAlertModal,
  setShowEditRoleModal,
  setShowSignatureModal,
}) => {
  const appContext = useAppContext();
  const { hasRight, serviceConfig } = appContext;
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const routeParam = useParams<{
    entityId: string;
    alertId: string;
    distributionId: string;
  }>();
  return (
    <>
      {(alert || distribution) && (
        <>
          <NxpHeader
            onBackClick={history.goBack}
            titleContent={alert ? alert.subject : distribution.alert?.subject}
            actionContent={
              <Row gutter={10}>
                {alert ? (
                  <>
                    <Col>
                      <AlertModal
                        show={!!showEditRoleModal}
                        onClose={() => setShowEditRoleModal(false)}
                        modalState={ModalState.edit}
                        editAlert={alert}
                        onRefresh={onRefresh}
                      />
                    </Col>
                    {hasRight("alert-distribute") && (
                      <Col>
                        <NxpButtonWithConfirm
                          type="primary"
                          modalSize="small"
                          onConfirm={() => {
                            createDistribution(
                              routeParam?.entityId,
                              Number(routeParam?.alertId),
                              serviceConfig
                            );
                            /** Timeout is used to give backend time to update alert.distributions. **/
                            setTimeout(() => {
                              onRefresh();
                              notify.success(
                                t("alertPage.notifySuccess.DistributeAlert")
                              );
                            }, TIMEOUT_DURATION);
                          }}
                          modalContent={
                            <>
                              <p>{t("alertPage.DistributeAlertPrompt1")}</p>
                              <p>{t("alertPage.DistributeAlertPrompt2")}</p>
                            </>
                          }
                          modalTitle={t("app.common.DistributeAlert")}
                          confirmButtonContent={t("app.common.Confirm")}
                        >
                          {t("app.common.DistributeAlert")}
                        </NxpButtonWithConfirm>
                      </Col>
                    )}
                  </>
                ) : (
                  hasRight("alert-acknowledge") && (
                    <Col>
                      <NxpButton
                        type="primary"
                        onClick={() => {
                          setShowSignatureModal(true);
                        }}
                        hidden={!!distribution?.signature}
                      >
                        {t("app.common.Signature")}
                      </NxpButton>
                      <SignatureModal
                        show={!!showSignatureModal}
                        onClose={() => setShowSignatureModal(false)}
                        onRefresh={() => onRefresh()}
                      />
                    </Col>
                  )
                )}
              </Row>
            }
          />
          <NxpPanel
            titleContent={t("alertPage.GeneralInformation")}
            className={classes.general}
            disableCollapse
            extra={
              alert &&
              alert.distributionTime === "0" && (
                <NxpButton
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => setShowEditRoleModal(true)}
                >
                  {t("app.common.Edit")}
                </NxpButton>
              )
            }
          >
            <Row>
              <Col span={5}>
                <h6> {t("app.common.AlertNumber")}</h6>
                <p>{alert ? alert.code : distribution?.alert?.code}</p>
              </Col>
              <Col span={5}>
                <h6> {t("app.common.CreatedBy")}</h6>
                <p>
                  {alert
                    ? alert.creator?.name
                    : distribution?.alert?.creator?.name}
                </p>
              </Col>
              <Col span={6}>
                <h6> {t("app.common.LastDistributedOn")}</h6>
                {(alert && alert.distributionTime !== "0") ||
                (distribution && distribution.alert?.distributionTime) ? (
                  <div className={classes.date}>
                    <CalendarOutlined />
                    <p>
                      {alert &&
                        moment(Number(alert.distributionTime!)).format(
                          DateFormatPattern.date
                        )}
                      {distribution &&
                        moment(
                          Number(distribution?.alert?.distributionTime!)
                        ).format(DateFormatPattern.date)}
                    </p>
                  </div>
                ) : (
                  <p>N/A</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <h6> {t("app.common.Description")}</h6>
                <p className={classes.description}>
                  {alert && alert.description}
                </p>
                <p className={classes.description}>
                  {distribution && distribution?.alert?.description}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <h6> {t("app.common.Attachment")}</h6>
              </Col>
              {alert
                ? alert.attachments?.map((attachment) => (
                    <div className={classes.attachments}>
                      <div className={classes.card}>
                        {attachment.type?.includes("image") ? (
                          <Image src={attachment.originalUrl} />
                        ) : (
                          <a
                            href={attachment.originalUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <PaperClipOutlined />
                          </a>
                        )}
                      </div>
                      <div className={classes.cardFooter}>
                        <p>{attachment?.name}</p>
                      </div>
                    </div>
                  ))
                : distribution?.alert?.attachments?.map((attachment) => (
                    <div className={classes.attachments}>
                      <div className={classes.card}>
                        {attachment.type?.includes("image") ? (
                          <Image src={attachment.originalUrl} />
                        ) : (
                          <a
                            href={attachment.originalUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <PaperClipOutlined />
                          </a>
                        )}
                      </div>
                      <div className={classes.cardFooter}>
                        <p>{attachment?.name}</p>
                      </div>
                    </div>
                  ))}

              {alert && alert.attachments?.length === 0 && (
                <p> {t("app.common.NoAttachments")}</p>
              )}
              {distribution &&
                distribution?.alert?.attachments?.length === 0 && (
                  <p> {t("app.common.NoAttachments")}</p>
                )}
            </Row>
          </NxpPanel>
          {distribution && distribution?.signature && (
            <NxpPanel
              titleContent={t("app.common.Signature")}
              className={classes.signature}
              disableCollapse
              extra={
                <h3>{`${t("alertPage.SignedAt")} ${moment(
                  Number(distribution?.signature?.createdAt)
                ).format(DateFormatPattern.dateWithTime)}`}</h3>
              }
            >
              <Row>
                <Col span={24}>
                  <Image src={distribution?.signature?.originalUrl} />
                </Col>
              </Row>
            </NxpPanel>
          )}
          {alert &&
            alert.distributions &&
            alert.distributions.length > 0 &&
            distributionRows && (
              <div className={classes.table}>
                <NxpTable
                  columns={columns}
                  dataSource={distributionRows}
                  onRow={(distributionRow: DistributionRow) => ({
                    onClick: () => {
                      if (onRowClick) {
                        onRowClick(distributionRow);
                      }
                    },
                  })}
                ></NxpTable>
                {selectedDistributionRow && (
                  <DistributeAlertModal
                    distributions={alert.distributions.filter(
                      (distribution) =>
                        distribution.entityId === selectedDistributionRow.key
                    )}
                    show={!!showDistributeAlertModal}
                    onClose={() => setShowDistributeAlertModal(false)}
                  ></DistributeAlertModal>
                )}
              </div>
            )}
        </>
      )}
    </>
  );
};

export default AlertPageLayout;
