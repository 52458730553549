import "../../i18n/i18n";
import AppRouter from "./AppRouter";
import { appTheme } from "./appTheme";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ThemeProvider } from "react-jss";
import "./App.less";
import AppContextProvider from "./AppContext/AppContextProvider";
import getKeycloakInstance from "./keycloak";

function App() {
  const tokenLogger = (tokens: unknown) => {
    console.log("onKeycloakTokens", tokens);
  };

  return (
    <ReactKeycloakProvider
      authClient={getKeycloakInstance()}
      onTokens={tokenLogger}
      initOptions={{
        onLoad: "login-required",
        flow: "implicit",
      }}
    >
      <ThemeProvider theme={appTheme}>
        <AppContextProvider>
          <AppRouter />
        </AppContextProvider>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
