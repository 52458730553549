import { initReactI18next } from "react-i18next";

import {
  nxpUiTranslationDe,
  nxpUiTranslationEn,
  nxpUiTranslationEs,
  nxpUiTranslationZhHk,
} from "@nexploretechnology/nxp-ui";
import i18n from "i18next";
import PhraseInContextEditorPostProcessor from "i18next-phrase-in-context-editor-post-processor";

import de from "./de/translation.json";
import en from "./en/translation.json";
import es from "./es/translation.json";
import zh_hk from "./zh-HK/translation.json";

const resources = {
  en: {
    translation: { ...nxpUiTranslationEn, ...en },
  },
  "zh-HK": {
    translation: { ...nxpUiTranslationZhHk, ...zh_hk },
  },
  de: {
    translation: { ...nxpUiTranslationDe, ...de },
  },
  es: {
    translation: { ...nxpUiTranslationEs, ...es },
  },
};

i18n.use(initReactI18next);

const enableInContextProcessor = window.location.search.includes("phrase=true");
if (enableInContextProcessor) {
  i18n.use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled: true,
      fullReparse: true,
      autoLowercase: false,
      projectId: "ba59fc92760adc3a8f93f7cc85c99f10",
    })
  );
}

i18n.init({
  resources,
  fallbackLng: "en", // default language
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  postProcess: enableInContextProcessor ? ["phraseInContextEditor"] : [],
});

export default i18n;
