/** Packages **/
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { notify } from "@nexploretechnology/nxp-ui";

/** Services-Utils **/
import useAppContext from "../../hooks/useAppContext";
import { User } from "../../services/app";
import {
  batchAddRoleAssignees,
  createRole,
  deleteRole,
  editRole,
  getRoles,
  Role,
  updateRoleAssignees,
} from "../../services/roles";
import { AddRoleForm } from "./AddRoleModal/AddRoleModal";
import { BatchAssignForm } from "./BatchAssignModal/BatchAssignModal";
import { EditRoleForm } from "./EditRoleModal/EditRoleModal";
/** Components **/
import RoleSettingLayout from "./RoleSettingLayout";

interface RoleSettingContainerProps {}

const RoleSettingContainer: React.FC<RoleSettingContainerProps> = () => {
  const [changed, setChanged] = useState<boolean[]>([] as boolean[]);
  const [deleted, setDeleted] = useState<boolean[]>([] as boolean[]);
  const [originalUserIds, setOriginalUserIds] = useState<string[][]>(
    [] as string[][]
  );
  const [rolesList, setRolesList] = useState<Role[] | undefined>([]);
  const [selected, setSelected] = useState<boolean[]>([] as boolean[]);
  const [userIds, setUserIds] = useState<string[][]>([] as string[][]);

  const appContext = useAppContext();
  const { serviceConfig, roles } = appContext;
  const routeParam = useParams<{
    entityId: string;
  }>();
  const { t } = useTranslation();

  const handleAddRole = async (form: AddRoleForm): Promise<boolean> => {
    if (!routeParam.entityId) return false;
    if (!rolesList) return false;
    try {
      const role = await createRole(routeParam.entityId, form, serviceConfig);
      setRolesList([...rolesList, role]);
      setUserIds([...userIds, role.assignees.map((user: User) => user.id)]);
      setChanged([...changed, false]);
      setDeleted([...deleted, false]);
      setSelected([...selected, false]);
      notify.success(
        t("roleSetting.notifySuccess.CreateRole", { value: role.name })
      );
      return true;
    } catch (e) {
      notify.error(
        `${t("roleSetting.notifyError.CreateRole")}: ${
          e instanceof Error ? e.message : `${e}`
        }`
      );
      return false;
    }
  };

  const handleAssigneeChange = (role: Role, assigneeIds: string[]) => {
    if (!rolesList) return;
    const index = rolesList.findIndex(
      (existingRole) => existingRole.id === role.id
    );
    const newUserIds = [...userIds];
    newUserIds[index] = assigneeIds;
    setUserIds(newUserIds);
    setChanged(changed.map((value, idx) => (idx === index ? true : value)));
  };

  const handleBatchAssign = async (form: BatchAssignForm): Promise<boolean> => {
    if (!routeParam.entityId) return false;
    if (!rolesList) return false;
    const newRoles = [...rolesList];
    const newUserIds = [...userIds];
    const newOriginalUserIds = [...originalUserIds];
    const newChanged = [...changed];

    let count = 0;
    try {
      for (let i = 0; i < newRoles.length; i++) {
        if (!selected[i]) continue;
        const role = await batchAddRoleAssignees(
          routeParam.entityId,
          newRoles[i].id,
          form.userIds,
          serviceConfig
        );
        newRoles[i] = role;
        newUserIds[i] = role.assignees.map((user) => user.id);
        newOriginalUserIds[i] = newUserIds[i];
        newChanged[i] = false;
        count++;
      }
      setRolesList(newRoles);
      setUserIds(newUserIds);
      setOriginalUserIds(newOriginalUserIds);
      setChanged(newChanged);
      notify.success(
        t("roleSetting.notifySuccess.BatchAssign", {
          value1: form.userIds.length,
          value2: count,
        })
      );
      return true;
    } catch (e) {
      notify.error(
        `${t("roleSetting.notifyError.BatchAssign")}: ${
          e instanceof Error ? e.message : `${e}`
        }`
      );
      return false;
    }
  };

  const handleDelete = async (role: Role): Promise<boolean> => {
    if (!routeParam.entityId) return false;
    if (!rolesList) return false;

    try {
      await deleteRole(routeParam.entityId, role.id, serviceConfig);
      notify.success(
        t("roleSetting.notifySuccess.DeleteRole", { value: role.name })
      );

      const index = rolesList.findIndex(
        (existingRole) => existingRole.id === role.id
      );

      setDeleted(deleted.map((value, idx) => (idx === index ? true : value)));
      return true;
    } catch (e) {
      notify.error(`${t("roleSetting.notifyError.DeleteRole", {
        value: role.name,
      })}
: ${e instanceof Error ? e.message : `${e}`}`);
      return false;
    }
  };

  const handleDiscard = () => {
    setUserIds(originalUserIds);
  };

  const handleEditRole = async (
    role: Role,
    form: EditRoleForm
  ): Promise<boolean> => {
    if (!routeParam.entityId) return false;
    if (!rolesList) return false;

    try {
      const editedRole = await editRole(
        routeParam.entityId,
        role.id,
        { name: form.name, description: form.description },
        serviceConfig
      );

      const index = rolesList.findIndex(
        (oldRole) => oldRole.id === editedRole.id
      );
      setRolesList((prev) => {
        const value = [...(prev || [])];
        value[index] = editedRole;
        return value;
      });

      notify.success(
        t("roleSetting.notifySuccess.UpdateRole", { value: role.name })
      );
      return true;
    } catch (e) {
      notify.error(
        `${t("roleSetting.notifyError.UpdateRole")}: ${
          e instanceof Error ? e.message : `${e}`
        }`
      );
      return false;
    }
  };

  const handleRoleSelect = (role: Role, value: boolean) => {
    if (!rolesList) return;

    const index = rolesList.findIndex(
      (existingRole) => existingRole.id === role.id
    );

    const newSelected = [...selected];
    newSelected[index] = value;
    setSelected(newSelected);
  };

  const handleSave = () => {
    if (!routeParam.entityId) return;
    if (!rolesList) return;

    if (!changed.some((value) => value)) {
      notify.info(t("roleSetting.notifyInfo.Changed"));
      return;
    }

    for (let i = 0; i < rolesList.length; i++) {
      if (!changed[i]) continue;
      const role = rolesList[i];
      const assignees = userIds[i];
      updateRoleAssignees(
        routeParam.entityId,
        role.id,
        assignees,
        serviceConfig
      )
        .then((role) => {
          const index = rolesList.findIndex(
            (oldRole) => oldRole.id === role.id
          );
          setRolesList((prev) => {
            const value = [...(prev || [])];
            value[index] = role;
            return value;
          });
          setUserIds((prev) => {
            const value = [...prev];
            value[index] = role.assignees.map((user) => user.id);
            return value;
          });

          setChanged((prev) => {
            const value = [...prev];
            value[index] = false;
            return value;
          });
          notify.success(
            `${t("roleSetting.notifySuccess.UpdateRoleAssignees")} ${role.name}`
          );
        })
        .catch((err) =>
          notify.error(`${t("app.common.Error")}${role.name} : ${err}`)
        );
    }
  };

  const fetch = useCallback(async () => {
    if (!routeParam.entityId) return;

    const roles: Role[] = await getRoles(routeParam.entityId, serviceConfig);
    if (roles) {
      const _roles = [...roles];
      _roles.sort((a: Role, b: Role) => {
        if (a?.name > b.name) return 1;
        if (a?.name < b.name) return -1;
        return 0;
      });
      setRolesList(_roles);

      const userIds = _roles.map((role) =>
        role.assignees ? role.assignees.map((user) => user.id) : []
      );

      setSelected(_roles.map((_) => false));
      setChanged(_roles.map((_) => false));
      setDeleted(_roles.map((_) => false));
      setUserIds(userIds);
      setOriginalUserIds(userIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParam?.entityId, serviceConfig, roles.length]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <RoleSettingLayout
      deleted={deleted}
      originalUserIds={originalUserIds}
      roles={rolesList}
      selected={selected}
      userIds={userIds}
      onAddRole={handleAddRole}
      onAssigneeChange={handleAssigneeChange}
      onBatchAssign={handleBatchAssign}
      onDelete={handleDelete}
      onDiscard={handleDiscard}
      onEditRole={handleEditRole}
      onRoleSelect={handleRoleSelect}
      onSave={handleSave}
    />
  );
};

export default RoleSettingContainer;
