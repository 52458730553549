import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { IdcardFilled, MailFilled } from "@ant-design/icons";
import {
  NxpBackTop,
  NxpLayout,
  NxpNavbarConfigMenu,
  NxpSidebarProps,
} from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";
import i18n from "i18next";

import useAppContext from "../../../hooks/useAppContext";
import adminIcon from "../../../images/sidebar/sidebar-admin.svg";
import alertsReceivedIcon from "../../../images/sidebar/sidebar-alerts-received.svg";
import alertsIcon from "../../../images/sidebar/sidebar-alerts.svg";
import { patchMyUserPreference } from "../../../services/app";

const useStyles = createUseStyles((theme) => ({
  appLayout: {},
  alerts: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${alertsIcon})`,
      transform: "scale(.8)",
    },
  },
  alertsReceived: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${alertsReceivedIcon})`,
      transform: "scale(1.1)",
    },
  },
  admin: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${adminIcon})`,
      transform: "scale(.8)",
    },
  },
  navBar: {},
  userInfo: {
    "& > span.anticon": {
      marginRight: theme.spacing(1),
    },
  },
}));

interface AppLayoutProps {}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const appContext = useAppContext();
  const {
    activeEntity,
    activeUser,
    language,
    serviceConfig,
    hasRight,
    onAppContextCacheItemUpdate,
  } = appContext;
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const changeLanguage = useCallback(
    async (key: string) => {
      const newUserPreference = await patchMyUserPreference(serviceConfig, {
        application: "entity",
        parameter: "language",
        value: key,
      });
      onAppContextCacheItemUpdate("language", key);
      return newUserPreference;
    },
    [serviceConfig, onAppContextCacheItemUpdate]
  );
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <NxpLayout
      className={classes.appLayout}
      navBarProps={{
        title: `Quality Alert`,
        subtitleContent: `${activeEntity?.name || "--"} (v0.2.0)`,
        username: activeUser?.name || "",
        userPopoverContent: (
          <>
            <p className={classes.userInfo}>
              <IdcardFilled /> {activeUser?.name}
            </p>
            <p className={classes.userInfo}>
              <MailFilled /> {activeUser?.email}
            </p>
          </>
        ),
        settingsPopoverContent: (
          <NxpNavbarConfigMenu
            activeLanguageKey={i18n.language}
            onLanguageChange={changeLanguage}
            onLogoutClick={() => keycloak.logout()}
            languageSettings={[
              {
                name: "English",
                key: "en",
              },
              {
                name: "中文",
                key: "zh-HK",
              },
              {
                name: "Deutsch",
                key: "de",
              },
              {
                name: "Español",
                key: "es",
              },
            ]}
          />
        ),
      }}
      sidebarProps={{
        items: [
          ...((activeEntity
            ? [
                hasRight("alert-create") || hasRight("alert-distribute")
                  ? {
                      type: "link",
                      href: `/entities/${activeEntity.id}/alerts`,
                      title: t("app.common.Alerts"),
                      className: classes.alerts,
                    }
                  : null,
                hasRight("alert-receive")
                  ? {
                      type: "link",
                      href: `/entities/${activeEntity.id}/alerts-received`,
                      title: t("app.common.AlertsReceived"),
                      className: classes.alertsReceived,
                    }
                  : null,
                hasRight("role-read") || hasRight("right-read")
                  ? {
                      type: "submenu",
                      title: t("app.common.Admin"),
                      className: classes.admin,
                      menuLinks: [
                        ...(hasRight("role-read")
                          ? [
                              {
                                href: `/entities/${activeEntity.id}/role`,
                                title: t("app.common.ManageRoles"),
                              },
                            ]
                          : []),
                        ...(hasRight("right-read")
                          ? [
                              {
                                href: `/entities/${activeEntity.id}/rights`,
                                title: t("app.common.AccessRights"),
                              },
                            ]
                          : []),
                      ],
                    }
                  : null,
              ]
            : []) as NxpSidebarProps["items"]),
        ],
      }}
    >
      {children}
      <NxpBackTop />
    </NxpLayout>
  );
};

export default AppLayout;
